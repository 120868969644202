/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  attemptForgotPasswordThunk,
  attemptLoginThunk,
  attemptRegisterThunk,
  attemptResetPasswordThunk,
  attemptResendVerificationThunk,
} from './thunks';

interface AuthPagesState {
  pending: boolean;
  registerSuccess: boolean;
  forgotPasswordSuccess: boolean;
  resetPasswordSuccess: boolean;
  verificationModal: { isOpen: boolean };
}

const initialState: AuthPagesState = {
  pending: false,
  registerSuccess: false,
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
  verificationModal: { isOpen: false },
};

const slice = createSlice({
  name: 'authPages',
  initialState,
  reducers: {
    setVerificationModalAction: (
      state: AuthPagesState,
      action: PayloadAction<boolean>,
    ) => {
      state.verificationModal = { isOpen: action.payload };
    },
    setRegisterSuccessAction: (
      state: AuthPagesState,
      action: PayloadAction<boolean>,
    ) => {
      state.registerSuccess = action.payload;
    },
    setForgotPasswordSuccessAction: (
      state: AuthPagesState,
      action: PayloadAction<boolean>,
    ) => {
      state.forgotPasswordSuccess = action.payload;
    },
    setResetPasswordSuccessAction: (
      state: AuthPagesState,
      action: PayloadAction<boolean>,
    ) => {
      state.resetPasswordSuccess = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(attemptLoginThunk.pending, (state: AuthPagesState) => {
      state.pending = true;
    });
    builder.addCase(attemptLoginThunk.fulfilled, (state: AuthPagesState) => {
      state.pending = false;
    });
    builder.addCase(
      attemptLoginThunk.rejected,
      (state: AuthPagesState, action: any) => {
        state.pending = false;
        if (action.payload === 'UNCONFIRMED_USER') {
          state.verificationModal = { isOpen: true };
        }
      },
    );
    builder.addCase(
      attemptResendVerificationThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptResendVerificationThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
        state.verificationModal = { isOpen: false };
      },
    );
    builder.addCase(
      attemptResendVerificationThunk.rejected,
      (state: AuthPagesState) => {
        state.pending = false;
      },
    );
    builder.addCase(attemptRegisterThunk.pending, (state: AuthPagesState) => {
      state.pending = true;
    });
    builder.addCase(attemptRegisterThunk.fulfilled, (state: AuthPagesState) => {
      state.pending = false;
      state.registerSuccess = true;
    });
    builder.addCase(
      attemptRegisterThunk.rejected,
      (state: AuthPagesState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
        state.forgotPasswordSuccess = true;
      },
    );
    builder.addCase(
      attemptForgotPasswordThunk.rejected,
      (state: AuthPagesState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.pending,
      (state: AuthPagesState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.fulfilled,
      (state: AuthPagesState) => {
        state.pending = false;
        state.resetPasswordSuccess = true;
      },
    );
    builder.addCase(
      attemptResetPasswordThunk.rejected,
      (state: AuthPagesState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  setVerificationModalAction,
  setRegisterSuccessAction,
  setForgotPasswordSuccessAction,
  setResetPasswordSuccessAction,
} = slice.actions;

export default slice.reducer;
