import React, { memo } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';

import RowItem from './components/RowItem';

import { COLORS, FONT_SIZE, FONTS } from '../../constants/styles';
import SocialMedia from '../SocialMedia';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.WHITE};
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    flex-direction: row;
    align-items: center;
  `,
  HeaderText: styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    color: ${COLORS.TEXT};
    font-size: ${FONT_SIZE.PX24};
    font-family: ${FONTS.SUPER_BOLD};
  `,
  CloseButton: styled.div`
    cursor: pointer;
  `,
  SocialMediaWrapper: styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  SocialMediaContainer: styled.div`
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
};

interface Props {
  closeDrawer: () => void;
}

const Drawer: React.FC<Props> = ({ closeDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const DRAWER_OPTIONS = [
    { key: 'home', label: 'Home', path: '/', hash: null },
    { key: 'team', label: 'Team', path: '/', hash: 'team' },
    { key: 'roadmap', label: 'Roadmap', path: '/', hash: 'roadmap' },
    { key: 'tc', label: 'T&Cs', path: '/terms', hash: null },
  ];

  const navTo = (path: string, hash: string | null) => {
    if (hash) {
      if (location.pathname === path) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView();
        }
      } else {
        navigate(`${path}#${hash}`);
      }
    } else {
      navigate(path);
    }
  };

  return (
    <ST.Wrapper>
      <ST.Header>
        <ST.HeaderText></ST.HeaderText>
        <ST.CloseButton onClick={closeDrawer}>
          <MdClose color={COLORS.TEXT} size={20} />
        </ST.CloseButton>
      </ST.Header>
      {DRAWER_OPTIONS.map(d => (
        <RowItem
          key={d.key}
          label={d.label}
          clickFcn={() => {
            closeDrawer();
            navTo(d.path, d.hash);
          }}
          height={'70px'}
          highlight={d.path === location.pathname && d.hash === null}
        />
      ))}
      <ST.SocialMediaWrapper>
        <ST.SocialMediaContainer>
          <SocialMedia />
        </ST.SocialMediaContainer>
      </ST.SocialMediaWrapper>
    </ST.Wrapper>
  );
};

export default memo(Drawer);
