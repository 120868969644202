/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getClubMetadataThunk, updateClubNameThunk } from './thunks';

export type ClubMetadataType = {
  isClubAdmin: boolean;
  clubID?: string;
  clubName: string;
};

interface ClubPageState extends ClubMetadataType {
  pending: boolean;
  editClubNameModal: { isOpen: boolean; data?: { name: string } };
}

const initialState: ClubPageState = {
  pending: false,
  isClubAdmin: false,
  clubID: undefined,
  clubName: '',
  editClubNameModal: { isOpen: false },
};

const slice = createSlice({
  name: 'clubPage',
  initialState,
  reducers: {
    setClubNameAction: (
      state: ClubPageState,
      action: PayloadAction<ClubMetadataType['clubName']>,
    ) => {
      state.clubName = action.payload;
    },
    openEditClubNameModalAction: (
      state: ClubPageState,
      action: PayloadAction<{ name: string }>,
    ) => {
      state.editClubNameModal = { isOpen: true, data: action.payload };
    },
    closeEditClubNameModalAction: (state: ClubPageState) => {
      state.editClubNameModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(getClubMetadataThunk.pending, (state: ClubPageState) => {
      state.pending = true;
    });
    builder.addCase(
      getClubMetadataThunk.fulfilled,
      (state: ClubPageState, action: PayloadAction<ClubMetadataType>) => {
        state.pending = false;
        state.isClubAdmin = action.payload.isClubAdmin;
        state.clubID = action.payload.clubID;
        state.clubName = action.payload.clubName;
      },
    );
    builder.addCase(getClubMetadataThunk.rejected, (state: ClubPageState) => {
      state.pending = false;
    });
    builder.addCase(updateClubNameThunk.pending, (state: ClubPageState) => {
      state.pending = true;
    });
    builder.addCase(
      updateClubNameThunk.fulfilled,
      (state: ClubPageState, action: PayloadAction<string>) => {
        state.pending = false;
        state.editClubNameModal = { isOpen: false };
        state.clubName = action.payload;
      },
    );
    builder.addCase(
      updateClubNameThunk.rejected,
      (state: ClubPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  setClubNameAction,
  openEditClubNameModalAction,
  closeEditClubNameModalAction,
} = slice.actions;

export default slice.reducer;
