import { createAsyncThunk } from '@reduxjs/toolkit';
import MemberManagementAPIs, {
  deleteMemberType,
  getMemberDropdownOptionsType,
  getMembersType,
  sendMemberInviteType,
  updateMemberType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getMembersThunk = createAsyncThunk(
  'memberManagement/getMembersThunk',
  async (
    { payload, refresh }: getMembersType,
    { dispatch, rejectWithValue },
  ) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await MemberManagementAPIs.getMembersAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'MEMBER_MANAGEMENT_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return { ...results, refresh };
  },
);

export const sendMemberInviteThunk = createAsyncThunk(
  'memberManagement/sendMemberInviteThunk',
  async (payload: sendMemberInviteType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await MemberManagementAPIs.sendMemberInviteAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'MEMBER_MANAGEMENT_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateMemberThunk = createAsyncThunk(
  'memberManagement/updateMemberThunk',
  async (payload: updateMemberType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await MemberManagementAPIs.updateMemberAPI(token, {
        ...payload,
        leagues: payload.leagues.map(d => d.value),
      });
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'MEMBER_MANAGEMENT_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const getMemberDropdownOptionsThunk = createAsyncThunk(
  'memberManagement/getMemberDropdownOptionsThunk',
  async (
    payload: getMemberDropdownOptionsType,
    { dispatch, rejectWithValue },
  ) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await MemberManagementAPIs.getMemberDropdownOptionsAPI(
        token,
        payload,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'MEMBER_MANAGEMENT_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const deleteMemberThunk = createAsyncThunk(
  'memberManagement/deleteMemberThunk',
  async (payload: deleteMemberType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await MemberManagementAPIs.deleteMemberAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'MEMBER_MANAGEMENT_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload.userID;
  },
);
