import { createAsyncThunk } from '@reduxjs/toolkit';
import LeaderboardPageAPIs, {
  getLeaderboardDataType,
  updatePlayerDataAPIType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getLeaderboardDataThunk = createAsyncThunk(
  'leaderboardPage/getLeaderboardData',
  async (payload: getLeaderboardDataType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await LeaderboardPageAPIs.getLeaderboardDataAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'LEADERBOARD_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const getSilentLeaderboardThunk = createAsyncThunk(
  'leaderboardPage/getSilentLeaderboard',
  async (payload: getLeaderboardDataType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await LeaderboardPageAPIs.getLeaderboardDataAPI(token, payload);
    } catch (err: any) {
      return rejectWithValue('');
    }

    return results;
  },
);

export const updatePlayerDataThunk = createAsyncThunk(
  'leaderboardPage/updatePlayerDataThunk',
  async (payload: updatePlayerDataAPIType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await LeaderboardPageAPIs.updatePlayerDataAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'LEADERBOARD_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return {};
  },
);
