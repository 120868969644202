/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DropdownOptionType,
  HolesType,
} from '../../../../../constants/commonTypes';
import { getScoringPageResponseType, updateSPScoresResponseType } from './apis';
import { getScoringPageThunk, updateSPScoresThunk } from './thunks';

export interface SPResultsType {
  scoreID: string;
  name: string;
  userID: string;
  teamName?: string;
  teeTime?: string | null;
  handicap?: number;
  hole_1?: number;
  hole_2?: number;
  hole_3?: number;
  hole_4?: number;
  hole_5?: number;
  hole_6?: number;
  hole_7?: number;
  hole_8?: number;
  hole_9?: number;
  hole_10?: number;
  hole_11?: number;
  hole_12?: number;
  hole_13?: number;
  hole_14?: number;
  hole_15?: number;
  hole_16?: number;
  hole_17?: number;
  hole_18?: number;
  lastUpdated: string;
}

export interface ScoringPageState {
  pending: boolean;
  localPending: boolean;
  scores: Array<SPResultsType>;
  holes: Array<HolesType>;
  teeTimesList: Array<DropdownOptionType>;
  selectedTeeTime: DropdownOptionType;
  lastUpdatedScores: { [key: string]: string };
}

const initialState: ScoringPageState = {
  pending: false,
  localPending: false,
  scores: [],
  holes: [],
  teeTimesList: [],
  selectedTeeTime: { value: '', label: '' },
  lastUpdatedScores: {},
};

const slice = createSlice({
  name: 'scoringPage',
  initialState,
  reducers: {
    updateSelectedTeeTimeAction: (
      state: ScoringPageState,
      action: PayloadAction<DropdownOptionType>,
    ) => {
      state.selectedTeeTime = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getScoringPageThunk.pending, (state: ScoringPageState) => {
      state.localPending = true;
    });
    builder.addCase(
      getScoringPageThunk.fulfilled,
      (
        state: ScoringPageState,
        action: PayloadAction<getScoringPageResponseType>,
      ) => {
        state.localPending = false;
        state.scores = action.payload.scores.map(d => ({
          ...d,
          lastUpdated: new Date().toString(),
        }));
        state.holes = action.payload.holes;
        state.teeTimesList = action.payload.teeTimesList;
        if (action.payload.defaultTeeTime) {
          state.selectedTeeTime = action.payload.defaultTeeTime;
        }
      },
    );
    builder.addCase(
      getScoringPageThunk.rejected,
      (state: ScoringPageState, action: any) => {
        state.localPending = false;
        if (action.payload === 'NOT_AUTHORIZED') {
          state.scores = [];
          state.holes = [];
          state.teeTimesList = [];
        }
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(updateSPScoresThunk.pending, () => {
      // state.pending = true;
    });
    builder.addCase(
      updateSPScoresThunk.fulfilled,
      (
        state: ScoringPageState,
        action: PayloadAction<updateSPScoresResponseType>,
      ) => {
        // state.pending = false;
        state.scores = state.scores.map(d => ({
          ...d,
          ...(action.payload.scores.filter(g => d.userID === g.userID)[0] ||
            {}),
        }));
      },
    );
    builder.addCase(
      updateSPScoresThunk.rejected,
      (state: ScoringPageState, action: any) => {
        // state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const { updateSelectedTeeTimeAction } = slice.actions;

export default slice.reducer;
