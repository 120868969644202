/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DropdownOptionType,
  HolesType,
} from '../../../../../constants/commonTypes';
import { getLeaderboardDataResponseType } from './apis';
import {
  getLeaderboardDataThunk,
  getSilentLeaderboardThunk,
  updatePlayerDataThunk,
} from './thunks';

export type LeaderboardType = {
  scoreID?: string;
  name?: string;
  userID?: string;
  eventID?: string;
  teamID?: string;
  teamName?: string;
  teeTime?: string | null;
  handicap?: number;
  gcHandicap?: number;
  handicapType?: 'fixed' | 'calc' | 'external' | 'default' | 'capped';
  hole_1?: number;
  hole_2?: number;
  hole_3?: number;
  hole_4?: number;
  hole_5?: number;
  hole_6?: number;
  hole_7?: number;
  hole_8?: number;
  hole_9?: number;
  hole_10?: number;
  hole_11?: number;
  hole_12?: number;
  hole_13?: number;
  hole_14?: number;
  hole_15?: number;
  hole_16?: number;
  hole_17?: number;
  hole_18?: number;
  netScore?: number;
  grossScore?: number;
  skins?: Array<string>;
  skinTotal?: number;
};

interface LeaderboardPageState {
  pending: boolean;
  localPending: boolean;
  leaderboard: Array<LeaderboardType>;
  holes: Array<HolesType>;
  showSkins: boolean;
  dropdowns: {
    teams: Array<DropdownOptionType>;
  };
  totalPlayers: number;
  editModal: { isOpen: boolean; data: LeaderboardType };
}

const initialState: LeaderboardPageState = {
  pending: false,
  localPending: false,
  leaderboard: [],
  holes: [],
  showSkins: false,
  dropdowns: { teams: [] },
  totalPlayers: 0,
  editModal: {
    isOpen: false,
    data: {},
  },
};

const slice = createSlice({
  name: 'leaderboardPage',
  initialState,
  reducers: {
    openEditModalAction: (state, action: PayloadAction<LeaderboardType>) => {
      state.editModal = { isOpen: true, data: action.payload };
    },
    closeEditModalAction: state => {
      state.editModal = { isOpen: false, data: {} };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getLeaderboardDataThunk.pending,
      (state: LeaderboardPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      getLeaderboardDataThunk.fulfilled,
      (
        state: LeaderboardPageState,
        action: PayloadAction<getLeaderboardDataResponseType>,
      ) => {
        state.localPending = false;

        state.leaderboard = action.payload.leaderboard;
        state.holes = action.payload.holes;
        state.showSkins = action.payload.showSkins;
        state.dropdowns = action.payload.teams
          ? { ...state.dropdowns, teams: action.payload.teams }
          : state.dropdowns;
      },
    );
    builder.addCase(
      getLeaderboardDataThunk.rejected,
      (state: LeaderboardPageState, action: any) => {
        state.localPending = false;

        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(getSilentLeaderboardThunk.pending, () => {});
    builder.addCase(
      getSilentLeaderboardThunk.fulfilled,
      (
        state: LeaderboardPageState,
        action: PayloadAction<getLeaderboardDataResponseType>,
      ) => {
        state.leaderboard = action.payload.leaderboard;
        state.holes = action.payload.holes;
        state.showSkins = action.payload.showSkins;
        state.dropdowns = action.payload.teams
          ? { ...state.dropdowns, teams: action.payload.teams }
          : state.dropdowns;
      },
    );
    builder.addCase(getSilentLeaderboardThunk.rejected, () => {});
    builder.addCase(
      updatePlayerDataThunk.pending,
      (state: LeaderboardPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      updatePlayerDataThunk.fulfilled,
      (state: LeaderboardPageState) => {
        state.localPending = false;

        state.editModal = { isOpen: false, data: {} };
      },
    );
    builder.addCase(
      updatePlayerDataThunk.rejected,
      (state: LeaderboardPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
        // state.editModal = { isOpen: false, data: {} };
      },
    );
  },
});

export const { openEditModalAction, closeEditModalAction } = slice.actions;

export default slice.reducer;
