import { Auth } from 'aws-amplify';
import axios from 'axios';
import { API_URL } from '../../../constants/endpoints';
import { apiErrorProcessing } from '../../../constants/error_messages';

export interface getGlobalUsernameType {}
export interface getGlobalUsernameReturnType {
  firstName: string;
  lastName: string;
}

export default class GlobalAPIs {
  static async getTokenAPI() {
    return Auth.currentSession()
      .then(res => {
        const accessToken = res.getAccessToken();
        const jwt = accessToken.getJwtToken();

        return jwt;
      })
      .catch(() => null);
  }

  static getGlobalUsernameAPI(token: string, payload: getGlobalUsernameType) {
    return axios
      .post(`${API_URL}/users/getUsername`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getGlobalUsernameReturnType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
