import axios from 'axios';
import { API_URL } from '../../../constants/endpoints';
import { apiErrorProcessing } from '../../../constants/error_messages';
import { UserPageState } from './reducer';

export interface GetUserPageType {}

export interface UpdateUserDetailsType {
  firstName: string;
  lastName: string;
}

export interface GetUserPageResponseType {
  user: UserPageState['userDetails'];
  clubs: UserPageState['clubs'];
}

export default class UserPageAPIs {
  static getUserPageAPI(token: string, payload: GetUserPageType) {
    return axios
      .post(`${API_URL}/users/getUserPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: GetUserPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateUserDetailsAPI(token: string, payload: GetUserPageType) {
    return axios
      .post(`${API_URL}/users/updateUser`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }
}
