import axios from 'axios';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { AdminDataType, ClubLeagueType, LeagueMemberType } from './reducer';

export interface getClubSettingsType {
  clubID: string;
}

export interface getClubSettingsResponseType {
  admins: Array<AdminDataType>;
  leagues: ClubLeagueType;
}

export interface sendAdminInviteType {
  clubID: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export interface updateAdminType {
  clubID: string;
  email?: string;
  role: string;
  userID?: string;
}

export interface deleteAdminType {
  clubID: string;
  userID: string;
}

// MARK: CLUB LEAGUE
export interface addClubLeagueType {
  clubID: string;
  leagueName: string;
  leagueAlias: string;
  members: { add: Array<string>; delete: Array<string> };
}
export interface addClubLeagueResponseType {
  leagueID: string;
  leagueName: string;
  leagueAlias: string;
}

export interface updateClubLeagueType {
  leagueID: string;
  leagueName: string;
  leagueAlias: string;
  members: { add: Array<string>; delete: Array<string> };
}
export interface updateClubLeagueResponseType {}

export interface deleteClubLeagueType {
  leagueID: string;
}
export interface deleteClubLeagueResponseType {}

// MARK: LEAGUE TEAM

export interface addLeagueTeamType {
  leagueID: string;
  teamName: string;
}

export interface addLeagueTeamResponseType {
  teamID: string;
}

export interface updateLeagueTeamType {
  leagueID: string;
  teamID: string;
  teamName: string;
}

export interface deleteLeagueTeamType {
  leagueID: string;
  teamID: string;
}

export interface getAllMembersInLeagueType {
  clubID: string;
  leagueID: string;
}

export default class ClubSettingsPageAPI {
  static getClubSettingsAPI(token: string, payload: getClubSettingsType) {
    return axios
      .post(`${API_URL}/clubs/getClubSettings`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getClubSettingsResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static sendAdminInviteAPI(token: string, payload: sendAdminInviteType) {
    return axios
      .post(`${API_URL}/admins/sendAdminInvite`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: { test: string } }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateAdminAPI(token: string, payload: updateAdminType) {
    return axios
      .post(`${API_URL}/admins/updateAdmin`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static deleteAdminAPI(token: string, payload: deleteAdminType) {
    return axios
      .post(`${API_URL}/admins/deleteAdmin`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static addClubLeagueAPI(token: string, payload: addClubLeagueType) {
    return axios
      .post(`${API_URL}/leagues/addLeague`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: addClubLeagueResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateClubLeagueAPI(token: string, payload: updateClubLeagueType) {
    return axios
      .post(`${API_URL}/leagues/updateLeague`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: updateClubLeagueResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static deleteClubLeagueAPI(token: string, payload: deleteClubLeagueType) {
    return axios
      .post(`${API_URL}/leagues/deleteLeague`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: deleteClubLeagueResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static addLeagueTeamAPI(token: string, payload: addLeagueTeamType) {
    return axios
      .post(`${API_URL}/teams/addTeam`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: addLeagueTeamResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateLeagueTeamAPI(token: string, payload: updateLeagueTeamType) {
    return axios
      .post(`${API_URL}/teams/updateTeam`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static deleteLeagueTeamAPI(token: string, payload: deleteLeagueTeamType) {
    return axios
      .post(`${API_URL}/teams/deleteTeam`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static getAllMembersInLeagueAPI(
    token: string,
    payload: getAllMembersInLeagueType,
  ) {
    return axios
      .post(`${API_URL}/members/getAllMembersInLeague`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (result: { data: { members: Array<LeagueMemberType> } }) =>
          result.data.members,
      )
      .catch(apiErrorProcessing);
  }
}
