import axios from 'axios';
import {
  CurrencyTypes,
  DropdownOptionType,
  HandicapTeeTypes,
  HolesType,
  ScoringTypes,
} from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { FormatModalSaveType } from '../components/FormatModal';
import { EventFormatType } from './reducer';

export interface getEventSettingsType {
  eventID: string;
  eventDate: string;
}

export interface getEventSettingsResponseType {
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  defaultHandicapIndex: number;
  handicapSource: string;
  handicapTee: HandicapTeeTypes;
  handicapMax: number;
  pccAdjustment: number;
  formats: Array<EventFormatType>;
  teams: Array<DropdownOptionType>;
  formatDetails: Array<{
    formatID: string;
    formatName: string;
    formatDesc: string;
    formatDefault: string;
    payoutType: string;
  }>;
}

export interface updateEventDetailsType {
  eventID: string;
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  defaultHandicapIndex: number;
  handicapSource: string;
  handicapTee: HandicapTeeTypes;
  handicapMax: number;
  pccAdjustment: number;
}

export interface updateEventDateType {
  eventID: string;
  eventDate: string;
}

export interface deleteEventType {
  eventID: string;
}

export interface closeEventType {
  eventID: string;
}

export interface reopenEventType {
  eventID: string;
}

export interface addEventFormatType extends FormatModalSaveType {
  eventID: string;
}
export interface addEventFormatReturnType extends FormatModalSaveType {}
export interface updateEventFormatType extends FormatModalSaveType {}
export interface deleteEventFormatType {
  eventFormatID: string;
}

export interface getClubHoleDefaultsType {
  eventID: string;
}

export interface getClubHoleDefaultsResponseType {
  holes: Array<HolesType>;
}

export default class EventSettingsPageAPIs {
  static getEventSettingsAPI(token: string, payload: getEventSettingsType) {
    return axios
      .post(`${API_URL}/events/getEventSettings`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getEventSettingsResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateEventDetailsAPI(token: string, payload: updateEventDetailsType) {
    return axios
      .post(`${API_URL}/events/updateEventDetails`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static updateEventDateAPI(token: string, payload: updateEventDateType) {
    return axios
      .post(`${API_URL}/events/updateEventDate`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static deleteEventAPI(token: string, payload: deleteEventType) {
    return axios
      .post(`${API_URL}/events/deleteEvent`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static closeEventAPI(token: string, payload: closeEventType) {
    return axios
      .post(`${API_URL}/events/closeEvent`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static reopenEventAPI(token: string, payload: reopenEventType) {
    return axios
      .post(`${API_URL}/events/reopenEvent`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static addEventFormatAPI(token: string, payload: addEventFormatType) {
    return axios
      .post(`${API_URL}/events/addEventFormat`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: addEventFormatReturnType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateEventFormatAPI(token: string, payload: updateEventFormatType) {
    return axios
      .post(`${API_URL}/events/updateEventFormat`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static deleteEventFormatAPI(token: string, payload: deleteEventFormatType) {
    return axios
      .post(`${API_URL}/events/deleteEventFormat`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static getClubHoleDefaultsAPI(
    token: string,
    payload: getClubHoleDefaultsType,
  ) {
    return axios
      .post(`${API_URL}/clubs/getClubHoleDefaultsByEventID`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getClubHoleDefaultsResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
