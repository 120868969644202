import axios from 'axios';
import { API_URL } from '../../../constants/endpoints';
import { apiErrorProcessing } from '../../../constants/error_messages';
import { ClubMetadataType } from './reducer';

export interface getClubMetadataType {
  clubAlias: string;
}

export interface updateClubNameType {
  clubID: string;
  clubName: string;
}

export default class ClubPageAPIs {
  static getClubMetadataAPI(token: string, payload: getClubMetadataType) {
    return axios
      .post(`${API_URL}/clubs/getClubMetadata`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: ClubMetadataType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateClubNameAPI(token: string, payload: updateClubNameType) {
    return axios
      .post(`${API_URL}/clubs/updateClubName`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }
}
