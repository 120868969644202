import axios from 'axios';
import {
  DropdownOptionType,
  HolesType,
} from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { LeaderboardType } from './reducer';

export interface getLeaderboardDataType {
  eventID: string;
  orderBy: string;
  orderDir: string;
  search: string;
  hideZeros: boolean;
  getTeams: boolean;
}

export interface getLeaderboardDataResponseType {
  leaderboard: Array<LeaderboardType>;
  holes: Array<HolesType>;
  showSkins: boolean;
  teams?: Array<DropdownOptionType>;
}

type NullableHandicapLeaderboardType = Omit<LeaderboardType, 'handicap'> & {
  handicap?: number | null;
};
export interface updatePlayerDataAPIType {
  data: NullableHandicapLeaderboardType;
  eventID: string;
}

export default class LeaderboardPageAPIs {
  static getLeaderboardDataAPI(token: string, payload: getLeaderboardDataType) {
    return axios
      .post(`${API_URL}/events/getLeaderboardData`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getLeaderboardDataResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updatePlayerDataAPI(token: string, payload: updatePlayerDataAPIType) {
    return axios
      .post(`${API_URL}/events/updatePlayerData`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {})
      .catch(apiErrorProcessing);
  }
}
