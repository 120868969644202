import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setEventDateAction,
  setEventStatusAction,
  setGlobalEventSettings,
} from '../../../state/reducer';

import EventSettingsPageAPIs, {
  addEventFormatType,
  closeEventType,
  deleteEventFormatType,
  deleteEventType,
  getClubHoleDefaultsType,
  getEventSettingsType,
  reopenEventType,
  updateEventDateType,
  updateEventDetailsType,
  updateEventFormatType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getEventSettingsThunk = createAsyncThunk(
  'eventSettings/getEventSettingsThunk',
  async (payload: getEventSettingsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await EventSettingsPageAPIs.getEventSettingsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    dispatch(
      setGlobalEventSettings({
        scoringType: results.scoringType,
        handicapMax: results.handicapMax,
      }),
    );

    return results;
  },
);

export const updateEventDetailsThunk = createAsyncThunk(
  'eventSettings/updateEventDetailsThunk',
  async (payload: updateEventDetailsType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.updateEventDetailsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    dispatch(
      setGlobalEventSettings({
        scoringType: payload.scoringType,
        handicapMax: payload.handicapMax,
      }),
    );

    return payload;
  },
);

export const updateEventDateThunk = createAsyncThunk(
  'eventSettings/updateEventDateThunk',
  async (payload: updateEventDateType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.updateEventDateAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    dispatch(setEventDateAction(payload.eventDate));

    return true;
  },
);

export const deleteEventThunk = createAsyncThunk(
  'eventSettings/deleteEventThunk',
  async (payload: deleteEventType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.deleteEventAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return true;
  },
);

export const closeEventThunk = createAsyncThunk(
  'eventSettings/closeEventThunk',
  async (payload: closeEventType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.closeEventAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    dispatch(setEventStatusAction('CLOSED'));

    return true;
  },
);

export const reopenEventThunk = createAsyncThunk(
  'eventSettings/reopenEventThunk',
  async (payload: reopenEventType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.reopenEventAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    dispatch(setEventStatusAction('OPEN'));

    return true;
  },
);

export const addEventFormatThunk = createAsyncThunk(
  'eventSettings/addEventFormatThunk',
  async (payload: addEventFormatType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await EventSettingsPageAPIs.addEventFormatAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateEventFormatThunk = createAsyncThunk(
  'eventSettings/updateEventFormatThunk',
  async (payload: updateEventFormatType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.updateEventFormatAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const deleteEventFormatThunk = createAsyncThunk(
  'eventSettings/deleteEventFormatThunk',
  async (payload: deleteEventFormatType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await EventSettingsPageAPIs.deleteEventFormatAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload.eventFormatID;
  },
);

export const getClubHoleDefaultsThunk = createAsyncThunk(
  'eventSettings/getClubHoleDefaultsThunk',
  async (payload: getClubHoleDefaultsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await EventSettingsPageAPIs.getClubHoleDefaultsAPI(
        token,
        payload,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
