import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import globalReducer from '../containers/App/state/reducer';
import AuthPagesReducer from '../containers/AuthPages/state/reducer';
import MemmberManagementReducer from '../containers/ClubPage/pages/MemberManagementPage/state/reducer';
import ListClubEventsReducer from '../containers/ClubPage/pages/ListClubEventsPage/state/reducer';
import LeaderboardPageReducer from '../containers/EventPage/pages/LeaderboardPage/state/reducer';
import ClubPageReducer from '../containers/ClubPage/state/reducer';
import ClubSettingsReducer from '../containers/ClubPage/pages/ClubSettingsPage/state/reducer';
import EventPageReducer from '../containers/EventPage/state/reducer';
import UserPageReducer from '../containers/UserPage/state/reducer';
import EventSettingsPageReducer from '../containers/EventPage/pages/EventSettingsPage/state/reducer';
import ResultsPageReducer from '../containers/EventPage/pages/ResultsPage/state/reducer';
import ScoringPageReducer from '../containers/EventPage/pages/ScoringPage/state/reducer';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    authPages: AuthPagesReducer,
    memberManagement: MemmberManagementReducer,
    listClubEvents: ListClubEventsReducer,
    leaderboardPage: LeaderboardPageReducer,
    clubPage: ClubPageReducer,
    eventPage: EventPageReducer,
    clubSettings: ClubSettingsReducer,
    userPage: UserPageReducer,
    eventSettingsPage: EventSettingsPageReducer,
    resultsPage: ResultsPageReducer,
    scoringPage: ScoringPageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
