import axios from 'axios';
import {
  DropdownOptionType,
  HolesType,
} from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { SPResultsType } from './reducer';

export interface getScoringPageType {
  eventID: string;
  teeTime?: string;
}

export type getScoringPageResponseType = {
  scores: Array<SPResultsType>;
  holes: Array<HolesType>;
  teeTimesList: Array<DropdownOptionType>;
  defaultTeeTime: DropdownOptionType;
};

export interface updateSPScoresType {
  scores: Array<SPResultsType>;
  eventID: string;
}

export interface updateSPScoresResponseType {
  scores: Array<SPResultsType>;
}

export default class ScoringPageAPIs {
  static getScoringPageAPI(token: string, payload: getScoringPageType) {
    return axios
      .post(`${API_URL}/events/getScoringPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getScoringPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static updateSPScoresAPI(token: string, payload: updateSPScoresType) {
    return axios
      .post(`${API_URL}/events/updateScoringPageScores`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: updateSPScoresResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
