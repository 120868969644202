import { createAsyncThunk } from '@reduxjs/toolkit';

import ResultsPageAPIs, {
  getResultsPageType,
  getSummaryPageType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getResultsPageThunk = createAsyncThunk(
  'resultsPage/getResultsPage',
  async (payload: getResultsPageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ResultsPageAPIs.getResultsPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'RESULTS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const getSummaryPageThunk = createAsyncThunk(
  'resultsPage/getSummaryPage',
  async (payload: getSummaryPageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ResultsPageAPIs.getSummaryPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'RESULTS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
