import React, { memo } from 'react';
import styled from 'styled-components';
import ModalSkeleton from '../ModalSkeleton';

import { COLORS, FONTS, FONT_SIZE, PADDING } from '../../constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    font-size: ${FONT_SIZE.PX16};
    font-family: ${FONTS.NORMAL};
    color: ${COLORS.TEXT};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${PADDING.LG} ${PADDING.XL};
  `,
};

interface Props {
  title: string;
  message: string;
  closeModal: () => void;
  buttonText?: string;
}

const MessageModal: React.FC<Props> = ({
  title,
  message,
  closeModal,
  buttonText = 'OK',
}) => (
  <ModalSkeleton
    title={title}
    buttons={[
      {
        key: 'ok',
        text: buttonText,
        fcn: closeModal,
        bkg: COLORS.MAIN,
        color: COLORS.WHITE,
      },
    ]}
    maxWidth={350}
  >
    <ST.Wrapper>{message}</ST.Wrapper>
  </ModalSkeleton>
);

export default memo(MessageModal);
