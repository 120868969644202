/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CurrencyTypes,
  DropdownOptionType,
  EventStatusType,
  HandicapTeeTypes,
  HolesType,
  ScoringTypes,
} from '../../../../../constants/commonTypes';
import {
  addEventFormatReturnType,
  getClubHoleDefaultsResponseType,
  getEventSettingsResponseType,
  updateEventDetailsType,
  updateEventFormatType,
} from './apis';
import {
  addEventFormatThunk,
  closeEventThunk,
  deleteEventFormatThunk,
  deleteEventThunk,
  getClubHoleDefaultsThunk,
  getEventSettingsThunk,
  reopenEventThunk,
  updateEventDateThunk,
  updateEventDetailsThunk,
  updateEventFormatThunk,
} from './thunks';

export interface EventFormatType {
  eventFormatID: string;
  formatID: string;
  params: { [key: string]: any };
}

interface EventSettingsPageState {
  pending: boolean;
  localPending: boolean;
  eventStatus: EventStatusType;
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  defaultHandicapIndex: number;
  handicapTee: HandicapTeeTypes;
  pccAdjustment: number;
  eventFormats: Array<EventFormatType>;
  teams: Array<DropdownOptionType>;
  formatOptions: Array<DropdownOptionType>;
  formatOptionDetails: {
    [key: string]: {
      description: string;
      default: string;
      name: string;
      payoutType: string;
    };
  };
  editEventDetailsModal: {
    isOpen: boolean;
    data?: {
      eventDate: string;
      eventStatus: EventStatusType;
      holes: Array<HolesType>;
      courseRating: number;
      slopeRating: number;
      scoringType: ScoringTypes;
      currency: CurrencyTypes;
      defaultHandicapIndex: number;
      handicapSource: string;
      handicapTee: HandicapTeeTypes;
      handicapMax: number;
      pccAdjustment: number;
    };
  };
  deleteEventFormatModal: { isOpen: boolean; eventFormatID: string | null };
  deleteEventModal: boolean;
  editFormatModal: {
    isOpen: boolean;
    isNew: boolean;
    data?: {
      eventFormatID: string;
      formatID: string;
      params: { [key: string]: any };
    };
  };
  editDateModal: {
    isOpen: boolean;
    data?: {
      eventDate: string;
    };
  };
  clubDefaultHoles: Array<HolesType>;
}

const initialState: EventSettingsPageState = {
  pending: false,
  localPending: false,
  eventStatus: 'OPEN',
  holes: [],
  courseRating: 0,
  slopeRating: 0,
  scoringType: 'ADMIN',
  currency: 'CAD',
  defaultHandicapIndex: 0,
  handicapTee: 'WHITE',
  pccAdjustment: 0,
  eventFormats: [],
  teams: [],
  formatOptions: [],
  formatOptionDetails: {},
  editEventDetailsModal: { isOpen: false },
  deleteEventFormatModal: { isOpen: false, eventFormatID: null },
  deleteEventModal: false,
  editFormatModal: { isOpen: false, isNew: false },
  editDateModal: { isOpen: false },
  clubDefaultHoles: [],
};

const slice = createSlice({
  name: 'eventSettings',
  initialState,
  reducers: {
    openEditEventDetailsModalAction: (
      state,
      action: PayloadAction<{
        eventDate: string;
        eventStatus: EventStatusType;
        holes: Array<HolesType>;
        courseRating: number;
        slopeRating: number;
        scoringType: ScoringTypes;
        currency: CurrencyTypes;
        defaultHandicapIndex: number;
        handicapSource: string;
        handicapTee: HandicapTeeTypes;
        handicapMax: number;
        pccAdjustment: number;
      }>,
    ) => {
      state.editEventDetailsModal = { isOpen: true, data: action.payload };
    },
    closeEditEventDetailsModalAction: state => {
      state.editEventDetailsModal = { isOpen: false };
    },
    openDeleteEventFormatModalAction: (
      state: EventSettingsPageState,
      action: PayloadAction<string>,
    ) => {
      state.deleteEventFormatModal = {
        isOpen: true,
        eventFormatID: action.payload,
      };
    },
    closeDeleteEventFormatModalAction: (state: EventSettingsPageState) => {
      state.deleteEventFormatModal = { isOpen: false, eventFormatID: null };
    },
    openDeleteEventModalAction: (state: EventSettingsPageState) => {
      state.deleteEventModal = true;
    },
    closeDeleteEventModalAction: (state: EventSettingsPageState) => {
      state.deleteEventModal = false;
    },
    openEditFormatModalAction: (
      state: EventSettingsPageState,
      action: PayloadAction<{
        isNew: boolean;
        eventFormatID: string;
        formatID: string;
        params: { [key: string]: any };
      }>,
    ) => {
      state.editFormatModal = {
        isOpen: true,
        isNew: action.payload.isNew,
        data: {
          eventFormatID: action.payload.eventFormatID,
          formatID: action.payload.formatID,
          params: action.payload.params,
        },
      };
    },
    closeEditFormatModalAction: (state: EventSettingsPageState) => {
      state.editFormatModal = { isOpen: false, isNew: false };
    },
    openEditDateModalAction: (
      state: EventSettingsPageState,
      action: PayloadAction<{
        eventDate: string;
      }>,
    ) => {
      state.editDateModal = {
        isOpen: true,
        data: action.payload,
      };
    },
    closeEditDateModalAction: (state: EventSettingsPageState) => {
      state.editDateModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getEventSettingsThunk.pending,
      (state: EventSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      getEventSettingsThunk.fulfilled,
      (
        state: EventSettingsPageState,
        action: PayloadAction<getEventSettingsResponseType>,
      ) => {
        state.localPending = false;
        state.courseRating = action.payload.courseRating;
        state.slopeRating = action.payload.slopeRating;
        state.scoringType = action.payload.scoringType;
        state.currency = action.payload.currency;
        state.defaultHandicapIndex = action.payload.defaultHandicapIndex;
        state.handicapTee = action.payload.handicapTee;
        state.pccAdjustment = action.payload.pccAdjustment;
        state.holes = action.payload.holes;
        state.eventFormats = action.payload.formats;
        state.teams = action.payload.teams;

        const temp: EventSettingsPageState['formatOptionDetails'] = {};
        state.formatOptions = action.payload.formatDetails.map(d => {
          temp[d.formatID] = {
            description: d.formatDesc,
            name: d.formatName,
            payoutType: d.payoutType,
            default: d.formatDefault,
          };
          return {
            value: d.formatID,
            label: d.formatName,
          };
        });
        state.formatOptionDetails = temp;
      },
    );
    builder.addCase(
      getEventSettingsThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateEventDetailsThunk.pending,
      (state: EventSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      updateEventDetailsThunk.fulfilled,
      (
        state: EventSettingsPageState,
        action: PayloadAction<updateEventDetailsType>,
      ) => {
        state.localPending = false;
        state.editEventDetailsModal = { isOpen: false };
        state.courseRating = action.payload.courseRating;
        state.slopeRating = action.payload.slopeRating;
        state.scoringType = action.payload.scoringType;
        state.currency = action.payload.currency;
        state.defaultHandicapIndex = action.payload.defaultHandicapIndex;
        state.handicapTee = action.payload.handicapTee;
        state.pccAdjustment = action.payload.pccAdjustment;
        state.holes = action.payload.holes;
      },
    );
    builder.addCase(
      updateEventDetailsThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateEventDateThunk.pending,
      (state: EventSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      updateEventDateThunk.fulfilled,
      (state: EventSettingsPageState) => {
        state.pending = false;
        state.editDateModal = { isOpen: false };
      },
    );
    builder.addCase(
      updateEventDateThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteEventThunk.pending,
      (state: EventSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      deleteEventThunk.fulfilled,
      (state: EventSettingsPageState) => {
        state.pending = false;
        state.deleteEventModal = false;
      },
    );
    builder.addCase(
      deleteEventThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      closeEventThunk.pending,
      (state: EventSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      closeEventThunk.fulfilled,
      (state: EventSettingsPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      closeEventThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      reopenEventThunk.pending,
      (state: EventSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      reopenEventThunk.fulfilled,
      (state: EventSettingsPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      reopenEventThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      addEventFormatThunk.pending,
      (state: EventSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      addEventFormatThunk.fulfilled,
      (
        state: EventSettingsPageState,
        action: PayloadAction<addEventFormatReturnType>,
      ) => {
        state.localPending = false;
        state.eventFormats = [
          ...state.eventFormats,
          {
            eventFormatID: action.payload.eventFormatID,
            formatID: action.payload.formatID,
            params: JSON.parse(action.payload.params),
          },
        ];
      },
    );
    builder.addCase(
      addEventFormatThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateEventFormatThunk.pending,
      (state: EventSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      updateEventFormatThunk.fulfilled,
      (
        state: EventSettingsPageState,
        action: PayloadAction<updateEventFormatType>,
      ) => {
        state.localPending = false;
        state.eventFormats = state.eventFormats.map(d =>
          d.eventFormatID === action.payload.eventFormatID
            ? {
                ...d,
                params: JSON.parse(action.payload.params),
              }
            : d,
        );
      },
    );
    builder.addCase(
      updateEventFormatThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteEventFormatThunk.pending,
      (state: EventSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      deleteEventFormatThunk.fulfilled,
      (state: EventSettingsPageState, action: PayloadAction<string>) => {
        state.localPending = false;
        state.eventFormats = state.eventFormats.filter(
          d => d.eventFormatID !== action.payload,
        );
        state.deleteEventFormatModal = { isOpen: false, eventFormatID: null };
        state.editFormatModal = { isOpen: false, isNew: false };
      },
    );
    builder.addCase(
      deleteEventFormatThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.localPending = false;
        state.deleteEventFormatModal = { isOpen: false, eventFormatID: null };
        state.editFormatModal = { isOpen: false, isNew: false };
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      getClubHoleDefaultsThunk.pending,
      (state: EventSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getClubHoleDefaultsThunk.fulfilled,
      (
        state: EventSettingsPageState,
        action: PayloadAction<getClubHoleDefaultsResponseType>,
      ) => {
        state.pending = false;
        state.clubDefaultHoles = action.payload.holes;
      },
    );
    builder.addCase(
      getClubHoleDefaultsThunk.rejected,
      (state: EventSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  openEditEventDetailsModalAction,
  closeEditEventDetailsModalAction,
  openDeleteEventFormatModalAction,
  closeDeleteEventFormatModalAction,
  openDeleteEventModalAction,
  closeDeleteEventModalAction,
  openEditFormatModalAction,
  closeEditFormatModalAction,
  openEditDateModalAction,
  closeEditDateModalAction,
} = slice.actions;

export default slice.reducer;
