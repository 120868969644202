import { createAsyncThunk } from '@reduxjs/toolkit';

import EventPageAPIs, { getEventMetadataType } from './apis';
import { genericTokenCheck, processThunkError } from '../../App/state/thunks';

export const getEventMetadataThunk = createAsyncThunk(
  'eventPage/getEventMetadata',
  async (payload: getEventMetadataType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await EventPageAPIs.getEventMetadataAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'EVENT_PAGE',
        true,
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
