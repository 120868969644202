import { createAsyncThunk } from '@reduxjs/toolkit';

import UserPageAPIs, { GetUserPageType, UpdateUserDetailsType } from './apis';
import { genericTokenCheck, processThunkError } from '../../App/state/thunks';

export const getUserPageThunk = createAsyncThunk(
  'userPage/getUserPage',
  async (payload: GetUserPageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await UserPageAPIs.getUserPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'USER_PAGE');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateUserDetailsThunk = createAsyncThunk(
  'userPage/updateUserDetailsThunk',
  async (payload: UpdateUserDetailsType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await UserPageAPIs.updateUserDetailsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'USER_PAGE');
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);
