import { createAsyncThunk } from '@reduxjs/toolkit';
import ListClubEventsPageAPIs, {
  addClubEventType,
  getAllClubEventsType,
  getClubDefaultsType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getAllClubEventsThunk = createAsyncThunk(
  'listEvents/getAllEvents',
  async (payload: getAllClubEventsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ListClubEventsPageAPIs.getAllClubEventsAPI(
        token,
        payload,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'LIST_CLUB_EVENTS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const getClubDefaultsThunk = createAsyncThunk(
  'listEvents/getClubDefaultsThunk',
  async (payload: getClubDefaultsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ListClubEventsPageAPIs.getClubDefaultsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'LIST_CLUB_EVENTS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const addClubEventThunk = createAsyncThunk(
  'listEvents/addClubEvent',
  async (payload: addClubEventType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ListClubEventsPageAPIs.addClubEventAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'LIST_CLUB_EVENTS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
