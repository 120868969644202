export const COLORS = {
  MAIN: '#008763',
  BLACK: '#1C1C1C',
  TEXT_HIGHLIGHT: '#008763',
  TEXT: '#1C1C1C',
  LIGHT_GRAY: '#f7f7f7',
  GRAY: '#898a89',
  DARK_GRAY: '#404040',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',
  REAL_BLACK: '#000000',
  ORANGE: 'orange',
  RED: 'red',
  DARK_RED: '#C41320',
  BLUE: 'steelblue',
  PURPLE: '#565695',
  SMOKE: '#848884',
  LIGHT_PURPLE: '#9393BC',
  OG_GREEN: '#2bac56',
  BRIGHT_GREEN: '#4bf885',
  RANGE: [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
  ],
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: '#AD8A56',
  DEFAULT_RANK: '#95d1a3',
  BORDER_GRAY: 'rgba(0,0,0,0.42)',
};

export const FONTS = {
  LIGHT: 'LightText',
  NORMAL: 'NormalText',
  MEDIUM: 'MediumText',
  BOLD: 'BoldText',
  SUPER_BOLD: 'SuperBoldText',
};

export const FONT_SIZE = {
  PX2: '0.125rem',
  PX4: '0.25rem',
  PX6: '0.375rem',
  PX8: '0.5rem',
  PX10: '0.625rem',
  PX12: '0.75rem',
  PX14: '0.875rem',
  PX15: '0.9375rem',
  PX16: '1rem',
  PX18: '1.125rem',
  PX20: '1.25rem',
  PX22: '1.375rem',
  PX24: '1.5rem',
  PX26: '1.625rem',
  PX28: '1.75rem',
  PX30: '1.875rem',
  PX32: '2rem',
  PX34: '2.125rem',
  PX36: '2.25rem',
  PX38: '2.375rem',
  PX40: '2.5rem',
  PX42: '2.625rem',
  PX44: '2.75rem',
  PX46: '2.875rem',
  PX48: '3rem',
  PX50: '3.125rem',
  PX52: '3.25rem',
  PX54: '3.375rem',
  PX56: '3.5rem',
  PX58: '3.625rem',
  PX60: '3.75rem',
  PX62: '3.875rem',
  PX64: '4rem',
  PX66: '4.125rem',
  PX68: '4.25rem',
  PX70: '4.375rem',
  PX72: '4.5rem',
  PX74: '4.625rem',
  PX76: '4.75rem',
  PX78: '4.875rem',
  PX80: '5rem',
  PX82: '5.125rem',
  PX84: '5.25rem',
  PX86: '5.375rem',
  PX88: '5.5rem',
  PX90: '5.625rem',
  PX92: '5.75rem',
  PX94: '5.875rem',
  PX96: '6rem',
  PX98: '6.125rem',
  PX100: '6.25rem',
};

export const PADDING = {
  XS: '4px',
  SM: '8px',
  MD: '12px',
  LG: '16px',
  XL: '28px',
  XXL: '32px',
};

export const NO_SELECT = `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `;

export const NO_SCROLLBAR = `
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  `;

export const SCROLLBAR_STYLE = (color = '#babac0') => `
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    // background-color: #babac0;
    background-color: ${color};
    border-radius: 16px;
    border: 4px solid white;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const OVERFLOW_STYLE = (x: boolean, y: boolean, show: boolean) => `
  ${x ? 'overflow-x: auto;' : 'overflow-x: hidden;'}
  ${y ? 'overflow-y: auto;' : 'overflow-y: hidden;'}
  ${show ? SCROLLBAR_STYLE() : NO_SCROLLBAR}
`;

export const SHADOW_GENERATOR = (x: number, y: number, blur: number) => `
  -webkit-box-shadow: ${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: ${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75);
  box-shadow: ${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75);
  `;

export const SHADOW_GENERATOR_OBJ = (x: number, y: number, blur: number) => ({
  WebkitBoxShadow: `${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75)`,
  MozBoxShadow: `${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75)`,
  boxShadow: `${x}px ${y}px ${blur}px 0px rgba(0, 0, 0, 0.75)`,
});

export const ZINDEX = {
  MED: 999999,
  HIGH: 999999999999,
  EXTRA_HIGH: 9999999999999,
  MAX: 99999999999999,
};
