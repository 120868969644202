/* eslint-disable no-throw-literal */

export const apiErrorProcessing = (err: {
  response: { status: number; data: { error: { code: string } } };
}) => {
  if (err.response) {
    if (+err.response.status === 401) {
      throw { code: 'INVALID_TOKEN' };
    }

    if (+err.response.status === 400 && err.response.data) {
      if (err.response.data.error) {
        if (err.response.data.error.code) {
          throw { code: err.response.data.error.code };
        }
      }
    }
  }
  throw { code: 'MAJOR' };
};

export interface ErrorMessageType {
  title: string;
  message: string;
}

interface ErrorMessageScreenType {
  [key: string]: {
    [key: string]: ErrorMessageType;
  };
}

const MAJOR_ERROR: ErrorMessageType = {
  title: 'Something went wrong',
  message:
    'Looks like you are having trouble. Check your network connection and try again.',
};

const messages: ErrorMessageScreenType = {
  GLOBAL: {},
  REGISTER: {
    MISMATCH: {
      title: 'Registration Error',
      message:
        'Your passwords do not match. Please re-enter your passwords and try again',
    },
    InvalidParameterException: {
      title: 'Registration Error',
      message: 'Please complete all of the fields',
    },
    UsernameExistsException: {
      title: 'Registration Error',
      message:
        'The email address you entered is already in use. If this is your email try logging in or resetting your password.',
    },
    InvalidPasswordException: {
      title: 'Registration Error',
      message:
        'Please create a stronger password. It must contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    },
  },
  LOGIN: {
    NotAuthorizedException: {
      title: 'Login Error',
      message: 'Please enter a valid email and password',
    },
    InvalidParameterException: {
      title: 'Login Error',
      message: 'Please enter a valid email and password',
    },
    USER_DISABLED: {
      title: 'Login Error',
      message:
        'Unfortunately, your account has been disabled. If you think this was done incorrectly, please contact us.',
    },
  },
  VERIFY_EMAIL_PAGE: {},
  SIGNOUT: {},
  FORGOT_PASSWORD: {
    INVALID_PARAMETER: {
      title: 'Reset Error',
      message: 'Please enter a valid email',
    },
  },
  RESET_PASSWORD: {
    ExpiredCodeException: {
      title: 'Expired Code',
      message:
        'Looks like you are using an old code. Please re-request your code and try again.',
    },
    CodeMismatchException: {
      title: 'Incorrect Verification Code',
      message:
        'You provided an incorrect code. Please re-enter your code and try again.',
    },
    InvalidParameterException: {
      title: 'Reset Password Error',
      message:
        'Please enter a valid email and password. Ensure your password contains 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    },
  },
  MAIN_PAGE: {},
  USER_PAGE: {
    INVALID_FIRST_NAME: {
      title: 'Invalid First Name',
      message: 'Please enter a valid first name',
    },
    INVALID_LAST_NAME: {
      title: 'Invalid Last Name',
      message: 'Please enter a valid last name',
    },
  },
  CLUB_PAGE: {},
  EVENT_PAGE: {},
  CLUB_SETTINGS_PAGE: {
    INVALID_NAME: {
      title: 'Invalid Name',
      message: 'Please enter a valid name',
    },
    INVALID_ALIAS: {
      title: 'Invalid Alias',
      message:
        'Please enter a valid alias. It can only have letters, numbers and hyphens',
    },
    DUPLICATE_ALIAS: {
      title: 'Duplicate URL',
      message:
        'You already have a league with this URL. Please choose another one.',
    },
    INVALID_ADMIN_EMAIL: {
      title: 'Invalid Email',
      message: 'Please enter a valid email',
    },
    INVALID_ADMIN_ROLE: {
      title: 'Invalid Role',
      message: 'Please choose a role',
    },
    INVALID_CLUB_NAME: {
      title: 'Invalid Club Name',
      message: 'Enter a valid club name',
    },
    INVALID_LEAGUE_NAME: {
      title: 'Invalid League Name',
      message: 'Enter a valid league name',
    },
    INVALID_TEAM_NAME: {
      title: 'Invalid Team Name',
      message: 'Enter a valid team name',
    },
    EVENT_FORMATS_CONNECTED: {
      title: 'Unable to Delete Team',
      message:
        'To delete this team you must remove all references to the team. This includes all historical game formats like Team VS.',
    },
    TEAM_CONNECTED: {
      title: 'Unable to Delete Team',
      message:
        'To delete this team you must remove all references to the team.',
    },
  },
  LEADERBOARD_PAGE: {
    MISSING_HOLES: {
      title: 'Missing Hole Data',
      message:
        'No holes have been selected for this event. Tell your Club Admin to update the number of holes',
    },
  },
  LIST_CLUB_EVENTS_PAGE: {
    MISSING_USERS: {
      title: 'Missing Players',
      message:
        'You need to have at least one member in your league in order to create an round',
    },
    MISSING_HOLES: {
      title: 'Missing Hole Data',
      message:
        'No holes have been added. Please add at least 1 hole to continue.',
    },
  },
  MEMBER_MANAGEMENT_PAGE: {
    INVALID_MEMBER_NAME: {
      title: 'Invalid Name',
      message: 'Please enter a valid name',
    },
    INVALID_MEMBER_EMAIL: {
      title: 'Invalid Email',
      message: 'Please enter a valid email',
    },
    MISSING_LEAGUE: {
      title: 'Invalid League Selection',
      message: 'Please choose at least 1 league for this member',
    },
  },
  EVENT_SETTINGS_PAGE: {
    INVALID_SCORE_TYPE: {
      title: 'Invalid Score Type',
      message: 'Enter a valid scoring Type',
    },
    INVALID_EVENT_DATE: {
      title: 'Invalid Round Date',
      message: 'Enter a valid round date',
    },
    INVALID_COURSE_RATING: {
      title: 'Invalid Course Rating',
      message: 'Enter a valid course rating',
    },
    INVALID_SLOPE_RATING: {
      title: 'Invalid Slope Rating',
      message: 'Enter a valid slope rating',
    },
    INVALID_DEFAULT_HANDICAP: {
      title: 'Invalid Handicap Index',
      message: 'Enter a valid handicap index',
    },
    INVALID_HANDICAP_MAX: {
      title: 'Invalid Handicap Max',
      message: 'Enter a valid maximum handicap',
    },
  },
  RESULTS_PAGE: {},
  SCORING_PAGE: {
    NOT_AUTHORIZED: {
      title: 'No Access',
      message:
        'You do not have permission to edit these scores. Please contact your club administrator.',
    },
  },
  FORMAT_VALIDATION: {
    MISSING_SKINS_DATA: {
      title: 'Missing Skins Data',
      message: 'Please enter a valid value for each payout and each carryover',
    },
    MISSING_PAYOUT_DATA: {
      title: 'Missing Payout Data',
      message: 'Please enter a valid value for each payout',
    },
    MISSING_HOLES: {
      title: 'Missing Hole Data',
      message:
        'No holes have been added. Please add at least 1 hole to continue.',
    },
    MISSING_STABLEFORD_TIERS: {
      title: 'Missing Stableford Tiers',
      message: 'Please add at least one stableford tier',
    },
    MISSING_STABLEFORD_TIER_PAYOUT: {
      title: 'Missing Stableford Payouts',
      message: "Please enter a valid value for each tier's points.",
    },
    MISSING_TEAM_VS_TO_PAR_INVALID: {
      title: 'Missing Team VS Breakdown',
      message: 'Please enter a valid value',
    },
    MISSING_TEAM_VS_TO_PAR_BREAKDOWN: {
      title: 'Missing Team VS Breakdown',
      message: 'Please add at least 1 gross or net score per team',
    },
  },
};

export type ScreenKeyType =
  | 'GLOBAL'
  | 'REGISTER'
  | 'LOGIN'
  | 'SIGNOUT'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD'
  | 'VERIFY_EMAIL_PAGE'
  | 'MAIN_PAGE'
  | 'USER_PAGE'
  | 'CLUB_PAGE'
  | 'EVENT_PAGE'
  | 'CLUB_SETTINGS_PAGE'
  | 'LEADERBOARD_PAGE'
  | 'LIST_CLUB_EVENTS_PAGE'
  | 'MEMBER_MANAGEMENT_PAGE'
  | 'EVENT_SETTINGS_PAGE'
  | 'RESULTS_PAGE'
  | 'SCORING_PAGE'
  | 'FORMAT_VALIDATION';

export const ERRORS = (screen: ScreenKeyType, val: string) => {
  console.log(screen, val);
  return messages[screen][val] || MAJOR_ERROR;
};
