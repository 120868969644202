/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorMessageType } from '../../../constants/error_messages';
import { getGlobalUsernameReturnType } from './apis';
import { attemptSignoutThunk, getGlobalUsernameThunk } from './thunks';

interface GlobalState {
  showDrawer: boolean;
  pending: boolean;
  loggedIn: boolean;
  signoutTime: string | null;
  loginRedirect?: string;
  errorModal: { isOpen: boolean; title: string; message: string };
  collapse: boolean;
  username: { firstName: string; lastName: string };
}

const initialState: GlobalState = {
  showDrawer: false,
  pending: false,
  loggedIn: false,
  signoutTime: null,
  loginRedirect: undefined,
  errorModal: { isOpen: false, title: '', message: '' },
  collapse: false,
  username: { firstName: '', lastName: '' },
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setDrawerAction: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.showDrawer = action.payload;
    },
    setPending: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setAutoLoginAction: (state: GlobalState, action: PayloadAction<string>) => {
      state.loggedIn = true;
      state.loginRedirect = action.payload;
    },
    setLoggedInAction: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    attemptForceLoginAction: (state: GlobalState) => {
      state.loggedIn = false;
      state.loginRedirect = undefined;
      state.signoutTime = new Date().toString();
    },
    setErrorModalAction: (
      state: GlobalState,
      action: PayloadAction<GlobalState['errorModal']>,
    ) => {
      state.errorModal = action.payload;
    },
    openGlobalErrorModalAction: (
      state: GlobalState,
      action: PayloadAction<ErrorMessageType>,
    ) => {
      state.errorModal = { isOpen: true, ...action.payload };
    },
    setCollapseAction: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.collapse = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(attemptSignoutThunk.fulfilled, (state: GlobalState) => {
      state.loggedIn = false;
      state.loginRedirect = undefined;
    });
    builder.addCase(
      attemptSignoutThunk.rejected,
      (state: GlobalState, action: any) => {
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(getGlobalUsernameThunk.pending, () => {});
    builder.addCase(
      getGlobalUsernameThunk.fulfilled,
      (
        state: GlobalState,
        action: PayloadAction<getGlobalUsernameReturnType>,
      ) => {
        state.username = action.payload;
      },
    );
    builder.addCase(
      getGlobalUsernameThunk.rejected,
      (state: GlobalState, action: any) => {
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  setDrawerAction,
  setPending,
  setLoggedInAction,
  setAutoLoginAction,
  attemptForceLoginAction,
  setErrorModalAction,
  openGlobalErrorModalAction,
  setCollapseAction,
} = slice.actions;

export default slice.reducer;
