import { createAsyncThunk } from '@reduxjs/toolkit';
import ClubPageAPIs, { getClubMetadataType, updateClubNameType } from './apis';
import { genericTokenCheck, processThunkError } from '../../App/state/thunks';

export const getClubMetadataThunk = createAsyncThunk(
  'clubPage/getClubMetadata',
  async (payload: getClubMetadataType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubPageAPIs.getClubMetadataAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_PAGE',
        true,
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateClubNameThunk = createAsyncThunk(
  'clubSettings/updateClubNameThunk',
  async (payload: updateClubNameType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubPageAPIs.updateClubNameAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(err, dispatch, 'CLUB_PAGE');
      return rejectWithValue(errorReject);
    }

    return payload.clubName;
  },
);
