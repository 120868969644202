import axios from 'axios';
import {
  CurrencyTypes,
  HandicapTeeTypes,
  HolesType,
  ScoringTypes,
} from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { EventType } from './reducer';

export interface getAllClubEventsType {
  clubID: string;
}

export interface getClubDefaultsType {
  clubID: string;
}

export interface getClubDefaultsResponseType {
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  handicapIndex: number;
  handicapSource: string;
  handicapTee: HandicapTeeTypes;
  handicapMax: number;
}

export interface addClubEventType {
  leagueID: string;
  eventDate: string;
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  defaultHandicapIndex: number;
  handicapSource: string;
  handicapTee: HandicapTeeTypes;
  handicapMax: number;
  pccAdjustment: number;
}

export default class ListClubEventsPageAPIs {
  static getAllClubEventsAPI(token: string, payload: getAllClubEventsType) {
    return axios
      .post(`${API_URL}/events/getAllClubEvents`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (result: {
          data: {
            events: Array<EventType>;
          };
        }) => result.data,
      )
      .catch(apiErrorProcessing);
  }

  static getClubDefaultsAPI(token: string, payload: getClubDefaultsType) {
    return axios
      .post(`${API_URL}/clubs/getClubDefaultsByClubID`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getClubDefaultsResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static addClubEventAPI(token: string, payload: addClubEventType) {
    return axios
      .post(`${API_URL}/events/addEvent`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: EventType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
