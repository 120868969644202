/* eslint-disable indent */
import useSize from '@react-hook/size';
import React, { memo, ReactNode, useRef } from 'react';
import styled from 'styled-components';

import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
  SHADOW_GENERATOR,
} from '../../constants/styles';

const ST = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    ${NO_SELECT}
  `,
  Button: styled.div<{
    round?: boolean;
    width: number;
    horizontalMargin: string;
    verticalMargin: string;
    disabledBtn: boolean;
  }>`
    ${p => (p.round ? `width: ${p.width}px;` : '')}
    ${p => (p.round ? `border-radius: 100%;` : '')}
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: ${p => p.verticalMargin} ${p => p.horizontalMargin};
    ${p => (p.disabledBtn ? 'pointer-events: none;' : '')}
  `,
  ButtonImage: styled.img`
    max-height: 100%;
    max-width: 100%;

    ${SHADOW_GENERATOR(1, 1, 5)}

    :hover {
      opacity: 0.8;
    }
  `,
  ButtonText: styled.div<{
    bkg: string;
    color: string;
    hoverBkg: string;
    hoverColor: string;
    fontFamily: string;
    fontSize: string;
    horizontalPadding: string;
    verticalPadding: string;
    textAlign: string;
    border: string;
    borderColor: string;
    borderRadius: string;
  }>`
    height: 100%;
    width: 100%;
    padding: ${p => p.verticalPadding} ${p => p.horizontalPadding};
    border-radius: ${p => p.borderRadius};
    background-color: ${p => p.bkg};
    color: ${p => p.color};
    font-family: ${p => p.fontFamily};
    font-size: ${p => p.fontSize};
    display: flex;
    flex-direction: row;
    justify-content: ${p => p.textAlign};
    align-items: center;
    text-align: ${p => p.textAlign};
    border: ${p => p.border} solid ${p => p.borderColor};

    :hover {
      background-color: ${p => p.hoverBkg};
      color: ${p => p.hoverColor};
      border: ${p => p.border} solid ${p => p.hoverBkg};
    }
  `,
  ButtonIcon: styled.div<{
    addLeftMargin?: boolean;
    addRightMargin?: boolean;
    iconPadding: string;
  }>`
    margin-left: ${p => (p.addLeftMargin ? p.iconPadding : '0px')};
    margin-right: ${p => (p.addRightMargin ? p.iconPadding : '0px')};
    font-size: ${FONT_SIZE.PX26};
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};
export interface BUTTON_TYPE {
  key: string;
  fcn: () => void;
  icon?: ReactNode;
  text?: string;
  image?: ReactNode;
  bkg?: string;
  color?: string;
  hoverBkg?: string;
  hoverColor?: string;
  iconAfter?: boolean;
  fontFamily?: string;
  fontSize?: string;
  textAlign?: string;
  horizontalPadding?: string;
  verticalPadding?: string;
  horizontalMargin?: string;
  verticalMargin?: string;
  round?: boolean;
  border?: number;
  borderColor?: string;
  iconPadding?: string;
  borderRadius?: string;
  disabled?: boolean;
}
interface Props {
  buttons: Array<BUTTON_TYPE>;
}

const MultiButtonRow: React.FC<Props> = ({ buttons }) => {
  const wrapperRef = useRef(null);
  const [, height] = useSize(wrapperRef);
  return (
    <ST.Wrapper ref={wrapperRef}>
      {buttons.map(d => (
        <ST.Button
          key={d.key}
          onClick={d.fcn}
          round={d.round}
          width={height}
          horizontalMargin={
            (d.horizontalMargin && d.horizontalMargin !== '0px') ||
            buttons.length > 1 ||
            d.round
              ? PADDING.SM
              : '0px'
          }
          verticalMargin={d.verticalMargin || '0px'}
          disabledBtn={d.disabled || false}
        >
          {/* {d.image && <ST.ButtonImage src={d.image} />} */}

          {(d.text || d.icon) && (
            <ST.ButtonText
              bkg={d.bkg || COLORS.MAIN}
              color={d.color || COLORS.WHITE}
              hoverBkg={d.hoverBkg || COLORS.BLACK}
              hoverColor={d.hoverColor || COLORS.WHITE}
              fontFamily={d.fontFamily || FONTS.BOLD}
              fontSize={d.fontSize || FONT_SIZE.PX20}
              textAlign={d.textAlign || 'center'}
              horizontalPadding={d.horizontalPadding || PADDING.MD}
              verticalPadding={d.verticalPadding || PADDING.MD}
              border={`${d.border || 0}px`}
              borderColor={`${d.borderColor || COLORS.MAIN}`}
              borderRadius={d.borderRadius || '4px'}
            >
              {d.icon && !d.iconAfter && (
                <ST.ButtonIcon
                  addRightMargin={![undefined, '', null].includes(d.text)}
                  iconPadding={d.iconPadding || PADDING.SM}
                >
                  {d.icon}
                </ST.ButtonIcon>
              )}
              {d.text}
              {d.icon && d.iconAfter && (
                <ST.ButtonIcon
                  addLeftMargin={![undefined, '', null].includes(d.text)}
                  iconPadding={d.iconPadding || PADDING.SM}
                >
                  {d.icon}
                </ST.ButtonIcon>
              )}
            </ST.ButtonText>
          )}
        </ST.Button>
      ))}
    </ST.Wrapper>
  );
};

export default memo(MultiButtonRow);
