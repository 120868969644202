import axios from 'axios';
import { API_URL } from '../../../constants/endpoints';
import { apiErrorProcessing } from '../../../constants/error_messages';

export interface LoginAPIType {
  email: string;
  password: string;
}

export interface ResendVerificationAPIType {
  email: string;
}

export interface VerifyEmailAPIType {
  email: string;
  code: string;
}

export interface RegisterThunkParamsType {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface RegisterAPIType {
  email: string;
  firstName: string;
  lastName: string;
  subID: string;
}

export interface ForgotPasswordAPIType {
  email: string;
}

export interface ResetPasswordAPIType {
  email: string;
  verificationCode: string;
  password: string;
}

export default class AuthPagesAPIs {
  static registerAPI(payload: RegisterAPIType) {
    return axios
      .post(`${API_URL}/auth/register`, payload, {})
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static forgotPasswordAPI(payload: ForgotPasswordAPIType) {
    return axios
      .post(`${API_URL}/auth/forgotPassword`, payload, {})
      .then(() => true)
      .catch(apiErrorProcessing);
  }
}
