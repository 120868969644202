import axios from 'axios';
import { API_URL } from '../../../constants/endpoints';
import { apiErrorProcessing } from '../../../constants/error_messages';
import { EventMetadataType } from './reducer';

export interface getEventMetadataType {
  clubAlias: string;
  leagueAlias: string;
  eventAlias: string;
}

export default class EventPageAPIs {
  static getEventMetadataAPI(token: string, payload: getEventMetadataType) {
    return axios
      .post(`${API_URL}/events/getEventMetadata`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: EventMetadataType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
