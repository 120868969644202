/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CurrencyTypes,
  DropdownOptionType,
  HandicapTeeTypes,
  HolesType,
  ScoringTypes,
} from '../../../../../constants/commonTypes';
import { getClubDefaultsResponseType } from './apis';
import {
  addClubEventThunk,
  getAllClubEventsThunk,
  getClubDefaultsThunk,
} from './thunks';

export type EventType = {
  leagueAlias: string;
  eventID: string;
  eventAlias: string;
  eventDate: string;
  eventStatus: string;
  holes: Array<HolesType>;
  courseRating: number;
  slopeRating: number;
  scoringType: ScoringTypes;
  currency: CurrencyTypes;
  defaultHandicapIndex: number;
  handicapSource: string;
  handicapTee: HandicapTeeTypes;
  handicapMax: number;
  pccAdjustment: number;
  league: DropdownOptionType;
};

interface ListClubEventsPageState {
  pending: boolean;
  localPending: boolean;
  clubEvents: Array<EventType>;
  clubDefaults: {
    holes: Array<HolesType>;
    courseRating: number;
    slopeRating: number;
    scoringType: ScoringTypes;
    currency: CurrencyTypes;
    handicapIndex: number;
    handicapSource: string;
    handicapTee: HandicapTeeTypes;
    handicapMax: number;
  };
  newEventModal: {
    isOpen: boolean;
    data?: {
      leagueID: string;
      eventDate: string;
      holes: Array<HolesType>;
      courseRating: number;
      slopeRating: number;
      scoringType: ScoringTypes;
      currency: CurrencyTypes;
      defaultHandicapIndex: number;
      handicapSource: string;
      handicapTee: HandicapTeeTypes;
      handicapMax: number;
      pccAdjustment: number;
    };
  };
}

const initialState: ListClubEventsPageState = {
  pending: false,
  localPending: false,
  clubEvents: [],
  clubDefaults: {
    holes: [],
    courseRating: 0,
    slopeRating: 0,
    scoringType: 'ADMIN',
    currency: 'CAD',
    handicapIndex: 0,
    handicapSource: '',
    handicapTee: 'WHITE',
    handicapMax: 0,
  },
  newEventModal: { isOpen: false },
};

const slice = createSlice({
  name: 'listClubEvents',
  initialState,
  reducers: {
    openNewEventModalAction: (
      state,
      action: PayloadAction<ListClubEventsPageState['newEventModal']['data']>,
    ) => {
      state.newEventModal = { isOpen: true, data: action.payload };
    },
    closeNewEventModalAction: state => {
      state.newEventModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getAllClubEventsThunk.pending,
      (state: ListClubEventsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      getAllClubEventsThunk.fulfilled,
      (
        state: ListClubEventsPageState,
        action: PayloadAction<{ events: Array<EventType> }>,
      ) => {
        state.localPending = false;
        state.clubEvents = action.payload.events;
      },
    );
    builder.addCase(
      getAllClubEventsThunk.rejected,
      (state: ListClubEventsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      getClubDefaultsThunk.pending,
      (state: ListClubEventsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getClubDefaultsThunk.fulfilled,
      (
        state: ListClubEventsPageState,
        action: PayloadAction<getClubDefaultsResponseType>,
      ) => {
        state.pending = false;
        state.clubDefaults = action.payload;
      },
    );
    builder.addCase(
      getClubDefaultsThunk.rejected,
      (state: ListClubEventsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      addClubEventThunk.pending,
      (state: ListClubEventsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      addClubEventThunk.fulfilled,
      (state: ListClubEventsPageState) => {
        state.localPending = false;
        state.newEventModal = { isOpen: false };
      },
    );
    builder.addCase(
      addClubEventThunk.rejected,
      (state: ListClubEventsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const { openNewEventModalAction, closeNewEventModalAction } =
  slice.actions;

export default slice.reducer;
