import { useRef, useEffect } from 'react';

export default (val: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = val;
  });

  return ref.current;
};
