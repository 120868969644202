import { some } from 'lodash';
import { RootState } from '../../../app/store';

export const selectShowDrawer = (state: RootState) => state.global.showDrawer;
export const selectPending = (state: RootState) =>
  some(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(state).map((d: keyof typeof state) => state[d].pending),
    Boolean,
  );

export const selectErrorModal = (state: RootState) => state.global.errorModal;
export const selectLoggedIn = (state: RootState) => state.global.loggedIn;
export const selectSignoutTime = (state: RootState) => state.global.signoutTime;
export const selectLoginRedirect = (state: RootState) =>
  state.global.loginRedirect;
export const selectCollapse = (state: RootState) => state.global.collapse;
export const selectGlobalUsername = (state: RootState) => state.global.username;
