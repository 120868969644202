import axios from 'axios';
import { DropdownOptionType } from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { MemberDataType } from './reducer';

export interface getMembersType {
  payload: {
    clubID: string;
    search: string;
    page: number;
    pageSize: number;
    orderBy: string;
    orderDir: string;
    leagueFilter: Array<string>;
  };
  refresh: boolean;
}

export interface sendMemberInviteType {
  clubID: string;
  firstName: string;
  lastName: string;
  email: string;
  leagues: Array<string>;
}

export interface updateMemberType {
  clubID: string;
  userID: string;
  leagues: Array<DropdownOptionType>;
}

export interface correctedUpdateMemberType {
  clubID: string;
  userID: string;
  leagues: Array<string>;
}

export interface getMemberDropdownOptionsType {
  clubID: string;
}

export interface getMembersResponseType {
  members: Array<MemberDataType>;
  page: number;
  fullPage: boolean;
}

export interface deleteMemberType {
  clubID: string;
  userID: string;
}

export default class MemberManagementPageAPIs {
  static getMembersAPI(token: string, payload: getMembersType['payload']) {
    return axios
      .post(`${API_URL}/members/getClubMembers`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getMembersResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static sendMemberInviteAPI(token: string, payload: sendMemberInviteType) {
    return axios
      .post(`${API_URL}/members/sendMemberInvite`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static updateMemberAPI(token: string, payload: correctedUpdateMemberType) {
    return axios
      .post(`${API_URL}/members/updateMember`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => true)
      .catch(apiErrorProcessing);
  }

  static getMemberDropdownOptionsAPI(
    token: string,
    payload: getMemberDropdownOptionsType,
  ) {
    return axios
      .post(`${API_URL}/members/getMemberDropdownOptions`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (result: {
          data: {
            leagues: Array<DropdownOptionType>;
          };
        }) => result.data,
      )
      .catch(apiErrorProcessing);
  }

  static deleteMemberAPI(token: string, payload: deleteMemberType) {
    return axios
      .post(`${API_URL}/members/deleteMember`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(result => result.data)
      .catch(apiErrorProcessing);
  }
}
