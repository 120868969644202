import React, { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  OVERFLOW_STYLE,
  PADDING,
  SHADOW_GENERATOR,
  ZINDEX,
} from '../../constants/styles';
import MultiButtonRow, { BUTTON_TYPE } from '../MultiButtonRow';

const ST = {
  Wrapper: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: ${ZINDEX.EXTRA_HIGH};
  `,
  Shadow: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: black;
    opacity: 0.3;
  `,
  Container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  ContentWrapper: styled.div<{ backgroundColor: string; maxWidth: number }>`
    background-color: ${p => p.backgroundColor};
    border-radius: 8px;
    max-height: 90%;
    width: 90%;
    max-width: ${p => p.maxWidth}px;
    display: flex;
    flex-direction: column;
    position: relative;
    ${SHADOW_GENERATOR(2, 2, 2)}
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${PADDING.MD};
    padding-left: ${PADDING.LG};
    padding-right: ${PADDING.LG};
    padding-top: ${PADDING.XL};
  `,
  Title: styled.div`
    font-family: ${FONTS.BOLD};
    font-size: ${FONT_SIZE.PX20};
    color: ${COLORS.BLACK};
    ${NO_SELECT}
  `,
  TitleLeftSide: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${PADDING.SM};
  `,
  TitleRightSide: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${PADDING.LG};
  `,
  TitleButtons: styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
  `,
  Content: styled.div`
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex: 1;

    ${OVERFLOW_STYLE(false, true, true)}
  `,
  ButtonWrapper: styled.div`
    height: 50px;
    width: 100%;
    margin: ${PADDING.MD} 0px;
    padding: 0px ${PADDING.LG};
  `,
  CloseButton: styled.div`
    cursor: pointer;
    :hover {
      path {
        stroke-width: 5px;
        stroke: ${COLORS.MAIN};
      }
    }
  `,
};

interface Props {
  children: ReactNode;
  title?: string;
  frontTitleButtons?: Array<BUTTON_TYPE>;
  titleButtons?: Array<BUTTON_TYPE>;
  buttons?: Array<BUTTON_TYPE>;
  backgroundColor?: string;
  closeFcn?: () => void;
  maxWidth: number;
}

const ModalSkeleton: React.FC<Props> = ({
  children,
  title = '',
  frontTitleButtons = [],
  titleButtons = [],
  buttons = [],
  backgroundColor = COLORS.WHITE,
  closeFcn = undefined,
  maxWidth = 300,
}) => (
  <ST.Wrapper>
    <ST.Shadow />
    <ST.Container>
      <ST.ContentWrapper backgroundColor={backgroundColor} maxWidth={maxWidth}>
        <ST.TitleWrapper>
          <ST.TitleLeftSide>
            <ST.Title>{title}</ST.Title>
            {frontTitleButtons.length > 0 && (
              <ST.TitleButtons>
                <MultiButtonRow buttons={frontTitleButtons} />
              </ST.TitleButtons>
            )}
          </ST.TitleLeftSide>
          <ST.TitleRightSide>
            {titleButtons.length > 0 && (
              <ST.TitleButtons>
                <MultiButtonRow buttons={titleButtons} />
              </ST.TitleButtons>
            )}
            {closeFcn !== undefined && (
              <ST.CloseButton onClick={closeFcn}>
                <GrClose />
              </ST.CloseButton>
            )}
          </ST.TitleRightSide>
        </ST.TitleWrapper>
        <ST.Content>{children}</ST.Content>
        {buttons.length > 0 && (
          <ST.ButtonWrapper>
            <MultiButtonRow buttons={buttons} />
          </ST.ButtonWrapper>
        )}
      </ST.ContentWrapper>
    </ST.Container>
  </ST.Wrapper>
);

export default memo(ModalSkeleton);
