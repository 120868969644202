/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventStatusType, ScoringTypes } from '../../../constants/commonTypes';
import { getEventMetadataThunk } from './thunks';

export type EventMetadataType = {
  isEventAdmin: boolean;
  eventID?: string;
  clubName: string;
  leagueName: string;
  eventDate?: string;
  eventStatus?: EventStatusType;
  scoringType: ScoringTypes;
  handicapSource: string;
  handicapSourceLabel: string;
  handicapMax: number;
};

interface EventPageState extends EventMetadataType {
  pending: boolean;
  localPending: boolean;
}

const initialState: EventPageState = {
  pending: false,
  localPending: false,
  isEventAdmin: false,
  eventID: undefined,
  clubName: '',
  leagueName: '',
  eventDate: undefined,
  eventStatus: undefined,
  scoringType: 'ADMIN',
  handicapSource: '',
  handicapSourceLabel: '',
  handicapMax: 0,
};

const slice = createSlice({
  name: 'eventPage',
  initialState,
  reducers: {
    setEventDateAction: (
      state: EventPageState,
      action: PayloadAction<string>,
    ) => {
      state.eventDate = action.payload;
    },
    setEventStatusAction: (
      state: EventPageState,
      action: PayloadAction<EventStatusType>,
    ) => {
      state.eventStatus = action.payload;
    },
    setGlobalEventSettings: (
      state: EventPageState,
      action: PayloadAction<{
        scoringType: EventPageState['scoringType'];
        handicapMax: EventPageState['handicapMax'];
      }>,
    ) => {
      state.scoringType = action.payload.scoringType;
      state.handicapMax = action.payload.handicapMax;
    },
  },
  extraReducers: builder => {
    builder.addCase(getEventMetadataThunk.pending, (state: EventPageState) => {
      state.localPending = true;
    });
    builder.addCase(
      getEventMetadataThunk.fulfilled,
      (state: EventPageState, action: PayloadAction<EventMetadataType>) => {
        state.localPending = false;
        state.isEventAdmin = action.payload.isEventAdmin;
        state.eventID = action.payload.eventID;
        state.clubName = action.payload.clubName;
        state.leagueName = action.payload.leagueName;
        state.eventDate = action.payload.eventDate;
        state.eventStatus = action.payload.eventStatus;
        state.scoringType = action.payload.scoringType;
        state.handicapSource = action.payload.handicapSource;
        state.handicapSourceLabel = action.payload.handicapSourceLabel;
        state.handicapMax = action.payload.handicapMax;
      },
    );
    builder.addCase(getEventMetadataThunk.rejected, (state: EventPageState) => {
      state.localPending = false;
    });
  },
});

export const {
  setEventDateAction,
  setEventStatusAction,
  setGlobalEventSettings,
} = slice.actions;

export default slice.reducer;
