import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { isEqual } from 'lodash';
import useSize from '@react-hook/size';
import { Amplify, Hub } from 'aws-amplify';

import GlobalStyle from '../../utils/global-styles';
import PendingScreen from '../../components/PendingScreen';
import Drawer from '../../components/Drawer';

import HomePage from '../HomePage/Loadable';
import MainPage from '../MainPage/Loadable';
import UserPage from '../UserPage/Loadable';
import LoginPage from '../AuthPages/LoginPage/Loadable';
import RegisterPage from '../AuthPages/RegisterPage/Loadable';
import ForgotPasswordPage from '../AuthPages/ForgotPasswordPage/Loadable';
import ResetPasswordPage from '../AuthPages/ResetPasswordPage/Loadable';
import PageNotFound from '../PageNotFound/Loadable';
import ClubPage from '../ClubPage/Loadable';
import EventPage from '../EventPage/Loadable';
import TermsAndConditions from '../TermsAndConditions/Loadable';
import PrivacyPage from '../PrivacyPage/Loadable';
import UnauthorizedPage from '../UnauthorizedPage/Loadable';

import { COLORS, ZINDEX } from '../../constants/styles';
import {
  setCollapseAction,
  setDrawerAction,
  setErrorModalAction,
  setLoggedInAction,
} from './state/reducer';
import {
  selectErrorModal,
  selectLoggedIn,
  selectLoginRedirect,
  selectPending,
  selectShowDrawer,
  selectSignoutTime,
} from './state/selectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import usePrevious from '../../customHooks/usePrevious';
import MessageModal from '../../components/MessageModal';
import { attemptAutoLoginThunk, getGlobalUsernameThunk } from './state/thunks';

const {
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_AUTH_FLOW,
  REACT_APP_AWS_CLIENT_ID,
} = process.env;

Amplify.configure({
  Auth: {
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    region: REACT_APP_AWS_REGION,
    authenticationFlowType: REACT_APP_AWS_AUTH_FLOW,
    userPoolWebClientId: REACT_APP_AWS_CLIENT_ID,
  },
});

const ST = {
  Wrapper: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    background-color: ${COLORS.WHITE};
  `,
  Body: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  `,
  DrawerWrapper: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 300px;
  `,
  ErrorModal: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: ${ZINDEX.MAX};
  `,
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const bodyRef = useRef(null);

  const dispatch = useAppDispatch();
  const showDrawer = useAppSelector(selectShowDrawer);
  const pending = useAppSelector(selectPending);
  const errorModal = useAppSelector(selectErrorModal);
  const loggedIn = useAppSelector(selectLoggedIn);
  const signoutTime = useAppSelector(selectSignoutTime);
  const loginRedirect = useAppSelector(selectLoginRedirect);

  const closeDrawer = () => {
    dispatch(setDrawerAction(false));
  };
  const [width] = useSize(bodyRef);
  const collapse = width < 700;

  const prevSignoutTime = usePrevious(signoutTime);

  useEffect(() => {
    if (!isEqual(prevSignoutTime, signoutTime) && signoutTime !== null) {
      navigate(
        location.pathname === '/auth/login'
          ? '/auth/login'
          : `/auth/login?redirect=${location.pathname}`,
      );
    }
  }, [prevSignoutTime, signoutTime]);

  const prevLoggedIn = usePrevious(loggedIn);

  useEffect(() => {
    if (!isEqual(prevLoggedIn, loggedIn)) {
      if (!prevLoggedIn && loggedIn) {
        navigate(loginRedirect || searchParams.get('redirect') || '/user');
      } else if (prevLoggedIn && !loggedIn) {
        navigate('/');
      }
    }
  }, [prevLoggedIn, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getGlobalUsernameThunk({}));
    }
  }, [loggedIn]);

  const onAuthChanged = ({
    payload: { event },
  }: {
    payload: { event: string };
  }) => {
    switch (event) {
      case 'signIn':
        dispatch(setLoggedInAction(true));
        break;
      case 'signOut':
        dispatch(setLoggedInAction(false));
        break;
      default:
        break;
    }
  };

  const closeErrorModal = () => {
    dispatch(setErrorModalAction({ isOpen: false, title: '', message: '' }));
  };

  const locationPath = location.pathname;
  useEffect(() => {
    closeErrorModal();
  }, [locationPath]);

  useEffect(() => {
    Hub.listen('auth', onAuthChanged);
    dispatch(attemptAutoLoginThunk(`${location.pathname}${location.hash}`));
  }, []);

  useEffect(() => {
    dispatch(setCollapseAction(collapse));
  }, [collapse]);

  return (
    <ST.Wrapper>
      <ST.Body ref={bodyRef}>
        <Routes>
          <Route path={'/'} element={<MainPage />} />
          <Route path={'/auth/login'} element={<LoginPage />} />
          <Route path={'/auth/register'} element={<RegisterPage />} />
          <Route path={'/auth/forgot'} element={<ForgotPasswordPage />} />
          <Route path={'/auth/reset'} element={<ResetPasswordPage />} />
          <Route path={'/unknown'} element={<PageNotFound />} />
          <Route path={'/user'} element={<UserPage />} />
          <Route path={'/clubs/:clubAlias'} element={<ClubPage />} />
          <Route
            path={'/clubs/:clubAlias/:leagueAlias/:eventAlias'}
            element={<EventPage initialTab={'leaderboard'} />}
          />
          <Route path={'/terms'} element={<TermsAndConditions />} />
          <Route path={'/privacy'} element={<PrivacyPage />} />
          <Route path={'*'} element={<HomePage />} />
          <Route path={'/unauthorized'} element={<UnauthorizedPage />} />
        </Routes>
      </ST.Body>
      {showDrawer && (
        <ST.DrawerWrapper>
          <Drawer closeDrawer={closeDrawer} />
        </ST.DrawerWrapper>
      )}
      {errorModal.isOpen && (
        <ST.ErrorModal>
          <MessageModal
            title={errorModal.title}
            message={errorModal.message}
            closeModal={closeErrorModal}
          />
        </ST.ErrorModal>
      )}
      {pending && <PendingScreen color={COLORS.MAIN} />}

      <GlobalStyle />
    </ST.Wrapper>
  );
};

export default memo(App);
