/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit as lodashOmit } from 'lodash';
import { MemberDataType } from '../../MemberManagementPage/state/reducer';
import {
  addClubLeagueResponseType,
  deleteClubLeagueType,
  deleteLeagueTeamType,
  getClubSettingsResponseType,
  updateAdminType,
  updateClubLeagueType,
  updateLeagueTeamType,
} from './apis';
import {
  getClubSettingsThunk,
  sendAdminInviteThunk,
  updateAdminThunk,
  deleteAdminThunk,
  addLeagueTeamThunk,
  updateLeagueTeamThunk,
  deleteLeagueTeamThunk,
  addClubLeagueThunk,
  updateClubLeagueThunk,
  deleteClubLeagueThunk,
  getAllMembersInLeagueThunk,
} from './thunks';

export type AdminDataType = {
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export type ClubLeagueType = {
  [key: string]: {
    leagueName: string;
    leagueAlias: string;
    teams: Array<{ teamID: string; teamName: string }>;
  };
};

export interface LeagueMemberType extends MemberDataType {
  inLeague: [];
}

interface ClubSettingsPageState {
  pending: boolean;
  localPending: boolean;
  admins: Array<AdminDataType>;
  messageModal: {
    isOpen: boolean;
    title: string;
    message: string;
  };
  editAdminModal: {
    isOpen: boolean;
    isNew: boolean;
    data?: AdminDataType;
  };
  deleteAdminModal: { isOpen: boolean; id?: string };
  clubLeagues: ClubLeagueType;
  clubLeagueModal: {
    isOpen: boolean;
    isNew: boolean;
    data?: {
      clubID: string;
      leagueID?: string;
      leagueName?: string;
      leagueAlias?: string;
      members?: Array<LeagueMemberType>;
    };
  };
  leagueTeamModal: {
    isOpen: boolean;
    isNew: boolean;
    data?: { leagueID: string; teamID?: string; teamName?: string };
  };
}

const initialState: ClubSettingsPageState = {
  pending: false,
  localPending: false,
  admins: [],
  messageModal: {
    isOpen: false,
    title: '',
    message: '',
  },
  editAdminModal: {
    isOpen: false,
    isNew: false,
  },
  deleteAdminModal: { isOpen: false },
  clubLeagues: {},
  clubLeagueModal: { isOpen: false, isNew: true },
  leagueTeamModal: { isOpen: false, isNew: true },
};

const slice = createSlice({
  name: 'clubSettings',
  initialState,
  reducers: {
    openNewAdminModalAction: (state: ClubSettingsPageState) => {
      state.editAdminModal = {
        isOpen: true,
        isNew: true,
        data: {
          userID: '',
          firstName: '',
          lastName: '',
          email: '',
          role: '',
        },
      };
    },
    openEditAdminModalAction: (
      state: ClubSettingsPageState,
      action: PayloadAction<AdminDataType>,
    ) => {
      state.editAdminModal = {
        isOpen: true,
        isNew: false,
        data: action.payload,
      };
    },
    closeAdminModalAction: (state: ClubSettingsPageState) => {
      state.editAdminModal = { isOpen: false, isNew: false };
    },
    closeMessageModalAction: (state: ClubSettingsPageState) => {
      state.messageModal = { isOpen: false, title: '', message: '' };
    },

    openClubLeagueModalAction: (
      state: ClubSettingsPageState,
      action: PayloadAction<{
        clubID: string;
        leagueID?: string;
        leagueName?: string;
        leagueAlias?: string;
        members: Array<LeagueMemberType>;
        isNew: boolean;
      }>,
    ) => {
      state.clubLeagueModal = {
        isOpen: true,
        isNew: action.payload.isNew,
        data: {
          clubID: action.payload.clubID,
          leagueID: action.payload.leagueID,
          leagueName: action.payload.leagueName,
          leagueAlias: action.payload.leagueAlias,
          members: action.payload.members,
        },
      };
    },
    closeClubLeagueModalAction: (state: ClubSettingsPageState) => {
      state.clubLeagueModal = { isOpen: false, isNew: false };
    },
    openLeagueTeamModalAction: (
      state: ClubSettingsPageState,
      action: PayloadAction<{
        leagueID: string;
        teamID?: string;
        teamName?: string;
        isNew: boolean;
      }>,
    ) => {
      state.leagueTeamModal = {
        isOpen: true,
        isNew: action.payload.isNew,
        data: {
          leagueID: action.payload.leagueID,
          teamID: action.payload.teamID,
          teamName: action.payload.teamName,
        },
      };
    },
    closeLeagueTeamModalAction: (state: ClubSettingsPageState) => {
      state.leagueTeamModal = { isOpen: false, isNew: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getClubSettingsThunk.pending,
      (state: ClubSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      getClubSettingsThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<getClubSettingsResponseType>,
      ) => {
        state.localPending = false;
        state.admins = action.payload.admins;
        state.clubLeagues = action.payload.leagues;
      },
    );
    builder.addCase(
      getClubSettingsThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      sendAdminInviteThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      sendAdminInviteThunk.fulfilled,
      (state: ClubSettingsPageState) => {
        state.pending = false;
        state.editAdminModal = { isOpen: false, isNew: false };
        state.messageModal = {
          isOpen: true,
          title: 'Success',
          message: 'An invite link has been sent.',
        };
      },
    );
    builder.addCase(
      sendAdminInviteThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        state.messageModal = {
          isOpen: true,
          title: 'Unsuccessful',
          message: 'Was unable to add this person',
        };
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateAdminThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      updateAdminThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<updateAdminType>,
      ) => {
        state.pending = false;
        state.editAdminModal = { isOpen: false, isNew: false };
        state.admins = state.admins.map(d => ({
          ...d,
          role:
            d.userID === action.payload.userID ? action.payload.role : d.role,
        }));
      },
    );
    builder.addCase(
      updateAdminThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        state.messageModal = {
          isOpen: true,
          title: 'Unsuccessful',
          message: 'Was unable to update this person',
        };
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteAdminThunk.pending,
      (state: ClubSettingsPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      deleteAdminThunk.fulfilled,
      (state: ClubSettingsPageState, action: PayloadAction<string>) => {
        state.localPending = false;
        state.deleteAdminModal = { isOpen: false };
        state.admins = state.admins.filter(d => d.userID !== action.payload);
      },
    );
    builder.addCase(
      deleteAdminThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    // MARK: CLUB LEAGUES
    builder.addCase(
      addClubLeagueThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      addClubLeagueThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<addClubLeagueResponseType>,
      ) => {
        state.pending = false;
        state.clubLeagueModal = { isOpen: false, isNew: false };
        state.clubLeagues[action.payload.leagueID] = {
          leagueName: action.payload.leagueName,
          leagueAlias: action.payload.leagueAlias,
          teams: [],
        };
      },
    );
    builder.addCase(
      addClubLeagueThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateClubLeagueThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      updateClubLeagueThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<updateClubLeagueType>,
      ) => {
        state.pending = false;
        state.clubLeagueModal = { isOpen: false, isNew: false };
        state.clubLeagues[action.payload.leagueID].leagueName =
          action.payload.leagueName;
        state.clubLeagues[action.payload.leagueID].leagueAlias =
          action.payload.leagueAlias;
      },
    );
    builder.addCase(
      updateClubLeagueThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteClubLeagueThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      deleteClubLeagueThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<deleteClubLeagueType>,
      ) => {
        state.pending = false;
        state.clubLeagueModal = { isOpen: false, isNew: false };

        state.clubLeagues = lodashOmit(
          { ...state.clubLeagues },
          action.payload.leagueID,
        );
      },
    );
    builder.addCase(
      deleteClubLeagueThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    // MARK: LEAGUE TEAMS
    builder.addCase(
      addLeagueTeamThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      addLeagueTeamThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<{
          leagueID: string;
          teamID: string;
          teamName: string;
        }>,
      ) => {
        state.pending = false;
        state.leagueTeamModal = { isOpen: false, isNew: false };
        state.clubLeagues[action.payload.leagueID].teams.push({
          teamID: action.payload.teamID,
          teamName: action.payload.teamName,
        });
      },
    );
    builder.addCase(
      addLeagueTeamThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateLeagueTeamThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      updateLeagueTeamThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<updateLeagueTeamType>,
      ) => {
        state.pending = false;
        state.leagueTeamModal = { isOpen: false, isNew: false };
        state.clubLeagues[action.payload.leagueID].teams = state.clubLeagues[
          action.payload.leagueID
        ].teams.map(d =>
          d.teamID === action.payload.teamID
            ? {
                teamID: action.payload.teamID,
                teamName: action.payload.teamName,
              }
            : d,
        );
      },
    );
    builder.addCase(
      updateLeagueTeamThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteLeagueTeamThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      deleteLeagueTeamThunk.fulfilled,
      (
        state: ClubSettingsPageState,
        action: PayloadAction<deleteLeagueTeamType>,
      ) => {
        state.pending = false;
        state.leagueTeamModal = { isOpen: false, isNew: false };
        state.clubLeagues[action.payload.leagueID].teams = state.clubLeagues[
          action.payload.leagueID
        ].teams.filter(d => d.teamID !== action.payload.teamID);
      },
    );
    builder.addCase(
      deleteLeagueTeamThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      getAllMembersInLeagueThunk.pending,
      (state: ClubSettingsPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getAllMembersInLeagueThunk.fulfilled,
      (state: ClubSettingsPageState) => {
        state.pending = false;
      },
    );
    builder.addCase(
      getAllMembersInLeagueThunk.rejected,
      (state: ClubSettingsPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  openNewAdminModalAction,
  openEditAdminModalAction,
  closeAdminModalAction,
  closeMessageModalAction,
  openClubLeagueModalAction,
  closeClubLeagueModalAction,
  openLeagueTeamModalAction,
  closeLeagueTeamModalAction,
} = slice.actions;

export default slice.reducer;
