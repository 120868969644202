/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { DropdownOptionType } from '../../../../../constants/commonTypes';
import { WinnersRowType } from '../components/FormatVisualizer/components/WinnerRow';
import { getResultsPageResponseType, getSummaryPageResponseType } from './apis';
import { getResultsPageThunk, getSummaryPageThunk } from './thunks';

export type EventFormatsType =
  | 'SKINS'
  | 'TEAM_VS_TO_PAR'
  | 'NET_HIDDEN_HOLES'
  | 'GROSS_HIDDEN_HOLES'
  | 'HIDDEN_PARTNERS'
  | 'NET_MATCH_PLAY_AGAINST_PAR'
  | 'STABLEFORD'
  | 'TOP_NET'
  | 'TOP_GROSS';

export type ResultsType = Array<{
  formatID: EventFormatsType;
  eventFormatID: string;
  name: string;
  description: string;
  results: {
    winners: WinnersRowType;
    leaderboard: Array<{
      [key: string]: any;
    }>;
    holesKeys: Array<string>;
  };
}>;

export const RESULTS_PAGE_ORDER = [
  'Summary',
  'Top Net',
  'Top Gross',
  'Skins',
  'Gross Hidden Holes',
  'Net Hidden Holes',
  'Hidden Partners',
  'Net Match Play Against Par',
  'Stableford',
  'Team VS',
  'Team Leaderboard',
];

export interface ResultsPageState {
  pending: boolean;
  localPending: boolean;
  results: ResultsType;
  pageOptions: Array<DropdownOptionType>;
  selectedPage: DropdownOptionType | null;
}

const initialState: ResultsPageState = {
  pending: false,
  localPending: false,
  results: [],
  pageOptions: [],
  selectedPage: null,
};

const slice = createSlice({
  name: 'resultsPage',
  initialState,
  reducers: {
    updateSelectedPageAction: (
      state: ResultsPageState,
      action: PayloadAction<DropdownOptionType | null>,
    ) => {
      state.selectedPage = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getResultsPageThunk.pending, (state: ResultsPageState) => {
      state.localPending = true;
    });
    builder.addCase(
      getResultsPageThunk.fulfilled,
      (
        state: ResultsPageState,
        action: PayloadAction<getResultsPageResponseType>,
      ) => {
        state.localPending = false;
        state.results = action.payload.results;
      },
    );
    builder.addCase(
      getResultsPageThunk.rejected,
      (state: ResultsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(getSummaryPageThunk.pending, (state: ResultsPageState) => {
      state.localPending = true;
    });
    builder.addCase(
      getSummaryPageThunk.fulfilled,
      (
        state: ResultsPageState,
        action: PayloadAction<getSummaryPageResponseType>,
      ) => {
        state.localPending = false;
        if (action.payload.initialize) {
          state.pageOptions = sortBy(action.payload.pages, d =>
            RESULTS_PAGE_ORDER.indexOf(d.label),
          );
          [state.selectedPage] = state.pageOptions;
        }
        state.results = sortBy(action.payload.results, d =>
          RESULTS_PAGE_ORDER.indexOf(d.name),
        );
      },
    );
    builder.addCase(
      getSummaryPageThunk.rejected,
      (state: ResultsPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const { updateSelectedPageAction } = slice.actions;

export default slice.reducer;
