/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOptionType } from '../../../constants/commonTypes';
import { GetUserPageResponseType, UpdateUserDetailsType } from './apis';
import { getUserPageThunk, updateUserDetailsThunk } from './thunks';

export interface UserPageEventType {
  eventDate: string;
  eventAlias: string;
}

interface UserPageScoreType {
  eventDate: string;
  handicap?: number;
  hole_1?: number;
  hole_2?: number;
  hole_3?: number;
  hole_4?: number;
  hole_5?: number;
  hole_6?: number;
  hole_7?: number;
  hole_8?: number;
  hole_9?: number;
  hole_10?: number;
  hole_11?: number;
  hole_12?: number;
  hole_13?: number;
  hole_14?: number;
  hole_15?: number;
  hole_16?: number;
  hole_17?: number;
  hole_18?: number;
}

export interface UserPageGraphData {
  winnings: Array<{ [key: string]: number | string }>;
  formats: Array<DropdownOptionType>;
  handicapGraph: Array<UserPageScoreType>;
  strokesGraph: Array<{
    x: number;
    count: number;
    value: number;
  }>;
}

export interface UserPageState {
  pending: boolean;
  userDetails: { firstName: string; lastName: string };
  clubs: Array<{
    clubName: string;
    clubAlias: string;
    leagueName: string;
    leagueAlias: string;
    memberSource: 'admin' | 'member' | 'both';
    events: Array<UserPageEventType>;
    graphData: UserPageGraphData;
  }>;
  editUserNameModal: {
    isOpen: boolean;
    data?: { firstName: string; lastName: string };
  };
}

const initialState: UserPageState = {
  pending: false,
  userDetails: { firstName: '', lastName: '' },
  clubs: [],
  editUserNameModal: { isOpen: false, data: { firstName: '', lastName: '' } },
};

const slice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    openEditUserNameModalAction: (
      state: UserPageState,
      action: PayloadAction<UserPageState['editUserNameModal']['data']>,
    ) => {
      state.editUserNameModal = { isOpen: true, data: action.payload };
    },
    closeEditUserNameModalAction: (state: UserPageState) => {
      state.editUserNameModal = { isOpen: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserPageThunk.pending, (state: UserPageState) => {
      state.pending = true;
    });
    builder.addCase(
      getUserPageThunk.fulfilled,
      (
        state: UserPageState,
        action: PayloadAction<GetUserPageResponseType>,
      ) => {
        state.pending = false;
        state.userDetails = action.payload.user;
        state.clubs = action.payload.clubs;
      },
    );
    builder.addCase(
      getUserPageThunk.rejected,
      (state: UserPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(updateUserDetailsThunk.pending, (state: UserPageState) => {
      state.pending = true;
    });
    builder.addCase(
      updateUserDetailsThunk.fulfilled,
      (state: UserPageState, action: PayloadAction<UpdateUserDetailsType>) => {
        state.pending = false;
        state.editUserNameModal = { isOpen: false };
        state.userDetails = {
          ...state.userDetails,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        };
      },
    );
    builder.addCase(
      updateUserDetailsThunk.rejected,
      (state: UserPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const { openEditUserNameModalAction, closeEditUserNameModalAction } =
  slice.actions;

export default slice.reducer;
