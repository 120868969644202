/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { DropdownOptionType } from '../../../../../constants/commonTypes';
import { getMembersResponseType, updateMemberType } from './apis';
import {
  deleteMemberThunk,
  getMemberDropdownOptionsThunk,
  getMembersThunk,
  sendMemberInviteThunk,
  updateMemberThunk,
} from './thunks';

export type MemberDataType = {
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  leagues: Array<DropdownOptionType>;
};

interface MembersManagementPageState {
  pending: boolean;
  localPending: boolean;
  members: Array<MemberDataType>;
  page: number;
  pageSize: number;
  fullPage: boolean;
  messageModal: {
    isOpen: boolean;
    title: string;
    message: string;
  };
  editMemberModal: {
    isOpen: boolean;
    isNew: boolean;
    data?: MemberDataType;
  };
  deleteModal: { isOpen: boolean; id?: string };
  leagueOptions: Array<DropdownOptionType>;
  membersLoading: boolean;
}

const initialState: MembersManagementPageState = {
  pending: false,
  localPending: false,
  members: [],
  page: 1,
  pageSize: 50,
  fullPage: true,
  messageModal: {
    isOpen: false,
    title: '',
    message: '',
  },
  editMemberModal: { isOpen: false, isNew: false },
  deleteModal: { isOpen: false },
  leagueOptions: [],
  membersLoading: false,
};

interface getMembersResponseWithRefreshType extends getMembersResponseType {
  refresh: boolean;
}

const slice = createSlice({
  name: 'memberManagement',
  initialState,
  reducers: {
    openNewMemberModalAction: (state: MembersManagementPageState) => {
      state.editMemberModal = {
        isOpen: true,
        isNew: true,
        data: {
          userID: '',
          firstName: '',
          lastName: '',
          email: '',
          leagues: [],
        },
      };
    },
    openEditMemberModalAction: (
      state: MembersManagementPageState,
      action: PayloadAction<MemberDataType>,
    ) => {
      state.editMemberModal = {
        isOpen: true,
        isNew: false,
        data: action.payload,
      };
    },
    closeMemberModalAction: (state: MembersManagementPageState) => {
      state.editMemberModal = { isOpen: false, isNew: false };
    },
    closeMessageModalAction: (state: MembersManagementPageState) => {
      state.messageModal = { isOpen: false, title: '', message: '' };
    },
    setMembersLoadingAction: (
      state: MembersManagementPageState,
      action: PayloadAction<boolean>,
    ) => {
      state.membersLoading = action.payload;
    },
    openDeleteModalAction: (
      state: MembersManagementPageState,
      action: PayloadAction<string>,
    ) => {
      state.deleteModal = { isOpen: true, id: action.payload };
    },
    closeDeleteModalAction: (state: MembersManagementPageState) => {
      state.deleteModal = {
        isOpen: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getMembersThunk.pending,
      (state: MembersManagementPageState, action: any) => {
        if (action.meta.arg.refresh) {
          state.localPending = true;
        } else {
          state.membersLoading = true;
        }
      },
    );
    builder.addCase(
      getMembersThunk.fulfilled,
      (
        state: MembersManagementPageState,
        action: PayloadAction<getMembersResponseWithRefreshType>,
      ) => {
        if (action.payload.refresh) {
          state.localPending = false;
        } else {
          state.membersLoading = false;
        }
        state.members = action.payload.refresh
          ? action.payload.members
          : uniqBy([...state.members, ...action.payload.members], 'userID');
        state.page = action.payload.page;
        state.fullPage = action.payload.fullPage;
      },
    );
    builder.addCase(
      getMembersThunk.rejected,
      (state: MembersManagementPageState, action: any) => {
        if (action.payload.refresh) {
          state.localPending = false;
        } else {
          state.membersLoading = false;
        }
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      sendMemberInviteThunk.pending,
      (state: MembersManagementPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      sendMemberInviteThunk.fulfilled,
      (state: MembersManagementPageState) => {
        state.pending = false;
        state.editMemberModal = { isOpen: false, isNew: false };
        state.messageModal = {
          isOpen: true,
          title: 'Success',
          message:
            'An invite link has been sent. Add them to a league to get them started',
        };
      },
    );
    builder.addCase(
      sendMemberInviteThunk.rejected,
      (state: MembersManagementPageState, action: any) => {
        state.pending = false;
        state.messageModal = {
          isOpen: true,
          title: 'Unsuccessful',
          message: 'Was unable to add this person',
        };
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      updateMemberThunk.pending,
      (state: MembersManagementPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      updateMemberThunk.fulfilled,
      (
        state: MembersManagementPageState,
        action: PayloadAction<updateMemberType>,
      ) => {
        state.localPending = false;
        state.editMemberModal = { isOpen: false, isNew: false };
        state.members = state.members.map(d => ({
          ...d,
          leagues:
            d.userID === action.payload.userID
              ? action.payload.leagues
              : d.leagues,
        }));
      },
    );
    builder.addCase(
      updateMemberThunk.rejected,
      (state: MembersManagementPageState, action: any) => {
        state.localPending = false;
        state.messageModal = {
          isOpen: true,
          title: 'Unsuccessful',
          message: 'Was unable to update this person',
        };
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      getMemberDropdownOptionsThunk.pending,
      (state: MembersManagementPageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getMemberDropdownOptionsThunk.fulfilled,
      (
        state: MembersManagementPageState,
        action: PayloadAction<{
          leagues: Array<DropdownOptionType>;
        }>,
      ) => {
        state.pending = false;
        state.leagueOptions = action.payload.leagues;
      },
    );
    builder.addCase(
      getMemberDropdownOptionsThunk.rejected,
      (state: MembersManagementPageState, action: any) => {
        state.pending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
    builder.addCase(
      deleteMemberThunk.pending,
      (state: MembersManagementPageState) => {
        state.localPending = true;
      },
    );
    builder.addCase(
      deleteMemberThunk.fulfilled,
      (state: MembersManagementPageState, action: PayloadAction<string>) => {
        state.localPending = false;
        state.deleteModal = { isOpen: false };
        state.members = state.members.filter(d => d.userID !== action.payload);
      },
    );
    builder.addCase(
      deleteMemberThunk.rejected,
      (state: MembersManagementPageState, action: any) => {
        state.localPending = false;
        // TODO: HANDLE THIS ERROR
        console.log('REJECTED', action.payload);
      },
    );
  },
});

export const {
  openNewMemberModalAction,
  openEditMemberModalAction,
  closeMemberModalAction,
  closeMessageModalAction,
  setMembersLoadingAction,
  openDeleteModalAction,
  closeDeleteModalAction,
} = slice.actions;

export default slice.reducer;
