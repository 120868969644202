import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { processThunkError } from '../../App/state/thunks';
import AuthPagesAPIs, {
  ForgotPasswordAPIType,
  LoginAPIType,
  ResetPasswordAPIType,
  ResendVerificationAPIType,
  RegisterThunkParamsType,
} from './apis';

export const attemptLoginThunk = createAsyncThunk(
  'authPages/attemptLoginThunk',
  async (payload: LoginAPIType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      await Auth.signIn(payload.email, payload.password);
    } catch (err: any) {
      const tempErr = err;
      if (tempErr.code === 'UserNotConfirmedException') {
        return rejectWithValue('UNCONFIRMED_USER');
      }
      if (tempErr.code) {
        if (
          tempErr.code === 'NotAuthorizedException' &&
          tempErr.message === 'User is disabled.'
        ) {
          tempErr.code = 'USER_DISABLED';
        }
      }
      const errorReject = await processThunkError(tempErr, dispatch, 'LOGIN');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const attemptResendVerificationThunk = createAsyncThunk(
  'authPages/attemptResendVerificationThunk',
  async (payload: ResendVerificationAPIType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      await Auth.resendSignUp(payload.email);
    } catch (err) {
      const errorReject = await processThunkError(err, dispatch, 'LOGIN');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const attemptRegisterThunk = createAsyncThunk(
  'authPages/attemptRegisterThunk',
  async (payload: RegisterThunkParamsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const newUser: { userSub: string } = await Auth.signUp({
        username: payload.email,
        password: payload.password,
        attributes: {
          email: payload.email,
        },
      });
      results = await AuthPagesAPIs.registerAPI({
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        subID: newUser.userSub,
      });
    } catch (err: any) {
      if (err.code === 'CodeDeliveryFailureException') {
        return results;
      }
      const errorReject = await processThunkError(err, dispatch, 'REGISTER');
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const attemptForgotPasswordThunk = createAsyncThunk(
  'authPages/attemptForgotPasswordThunk',
  async (payload: ForgotPasswordAPIType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      await Auth.forgotPassword(payload.email);
    } catch (err) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'FORGOT_PASSWORD',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const attemptResetPasswordThunk = createAsyncThunk(
  'authPages/attemptResetPasswordThunk',
  async (payload: ResetPasswordAPIType, { dispatch, rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(
        payload.email,
        payload.verificationCode,
        payload.password,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'RESET_PASSWORD',
      );
      return rejectWithValue(errorReject);
    }

    return 'SUCCESS';
  },
);
