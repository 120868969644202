import React, { memo, useRef } from 'react';
import styled from 'styled-components';
import useSize from '@react-hook/size';
import { FaDiscord, FaTwitter } from 'react-icons/fa';

import { DISCORD_LINK, TWITTER_LINK } from '../../constants/externalWebsites';
import { COLORS } from '../../constants/styles';

const ST = {
  Wrapper: styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    padding: 0px ${(p: { horizontalPadding: number }) => p.horizontalPadding}px;
    // justify-content: center;
  `,
  SocialMedia: styled.div`
    height: ${(p: { size: number }) => p.size}px;
    width: ${(p: { size: number }) => p.size}px;
    border-radius: 50%;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.MAIN};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    cursor: pointer;

    :hover {
      background-color: ${COLORS.BLACK};
      color: ${COLORS.WHITE};
    }
  `,
};
const createTwitterIcon = (size: number) => <FaTwitter size={size} />;
const createDiscordIcon = (size: number) => <FaDiscord size={size} />;

interface Props {
  horizontalPadding?: number;
}

const SocialMedia: React.FC<Props> = ({ horizontalPadding = 10 }) => {
  const wrapperRef = useRef(null);
  const [, height] = useSize(wrapperRef);
  const social = [
    {
      key: 'twitter',
      icon: createTwitterIcon,
      url: TWITTER_LINK,
    },
    {
      key: 'discord',
      icon: createDiscordIcon,
      url: DISCORD_LINK,
    },
  ];

  const openSocialMedia = (val: string) => {
    window.open(val, '_blank');
  };

  return (
    <ST.Wrapper ref={wrapperRef} horizontalPadding={horizontalPadding}>
      {social.map(d => (
        <ST.SocialMedia
          key={d.key}
          onClick={() => {
            openSocialMedia(d.url);
          }}
          size={height}
        >
          {d.icon(height / 2)}
        </ST.SocialMedia>
      ))}
    </ST.Wrapper>
  );
};

export default memo(SocialMedia);
