import axios from 'axios';
import { DropdownOptionType } from '../../../../../constants/commonTypes';
import { API_URL } from '../../../../../constants/endpoints';
import { apiErrorProcessing } from '../../../../../constants/error_messages';
import { ResultsType } from './reducer';

export interface getResultsPageType {
  eventID: string;
  eventFormatID: string;
}

export type getResultsPageResponseType = { results: ResultsType };

export interface getSummaryPageType {
  eventID: string;
  initialize: boolean;
}

export type getSummaryPageResponseType = {
  initialize: boolean;
  pages: Array<DropdownOptionType>;
  results: ResultsType;
};

export default class ResultsPageAPIs {
  static getResultsPageAPI(token: string, payload: getResultsPageType) {
    return axios
      .post(`${API_URL}/events/getResultsPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getResultsPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }

  static getSummaryPageAPI(token: string, payload: getSummaryPageType) {
    return axios
      .post(`${API_URL}/events/getSummaryPage`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result: { data: getSummaryPageResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
