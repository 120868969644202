import { createAsyncThunk } from '@reduxjs/toolkit';

import ScoringPageAPIs, {
  getScoringPageType,
  updateSPScoresType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getScoringPageThunk = createAsyncThunk(
  'scoringPage/getScoringPage',
  async (payload: getScoringPageType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ScoringPageAPIs.getScoringPageAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'SCORING_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateSPScoresThunk = createAsyncThunk(
  'scoringPage/updateSPScores',
  async (payload: updateSPScoresType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ScoringPageAPIs.updateSPScoresAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'SCORING_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
