import { createAsyncThunk } from '@reduxjs/toolkit';
import ClubSettingsPageAPIs, {
  getClubSettingsType,
  sendAdminInviteType,
  updateAdminType,
  deleteAdminType,
  addLeagueTeamType,
  updateLeagueTeamType,
  deleteLeagueTeamType,
  addClubLeagueType,
  updateClubLeagueType,
  deleteClubLeagueType,
  getAllMembersInLeagueType,
} from './apis';
import {
  genericTokenCheck,
  processThunkError,
} from '../../../../App/state/thunks';

export const getClubSettingsThunk = createAsyncThunk(
  'clubSettings/getClubSettingsThunk',
  async (payload: getClubSettingsType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubSettingsPageAPIs.getClubSettingsAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const sendAdminInviteThunk = createAsyncThunk(
  'clubSettings/sendAdminInviteThunk',
  async (payload: sendAdminInviteType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubSettingsPageAPIs.sendAdminInviteAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);

export const updateAdminThunk = createAsyncThunk(
  'clubSettings/updateAdminThunk',
  async (payload: updateAdminType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.updateAdminAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const deleteAdminThunk = createAsyncThunk(
  'clubSettings/deleteAdminThunk',
  async (payload: deleteAdminType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.deleteAdminAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload.userID;
  },
);

// MARK: CLUB LEAGUE
export const addClubLeagueThunk = createAsyncThunk(
  'clubSettings/addClubLeagueThunk',
  async (payload: addClubLeagueType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubSettingsPageAPIs.addClubLeagueAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return {
      leagueID: results.leagueID,
      leagueName: payload.leagueName,
      leagueAlias: payload.leagueAlias,
    };
  },
);

export const updateClubLeagueThunk = createAsyncThunk(
  'clubSettings/updateClubLeagueThunk',
  async (payload: updateClubLeagueType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.updateClubLeagueAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const deleteClubLeagueThunk = createAsyncThunk(
  'clubSettings/deleteClubLeagueThunk',
  async (payload: deleteClubLeagueType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.deleteClubLeagueAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

// MARK: LEAGUE TEAM

export const addLeagueTeamThunk = createAsyncThunk(
  'clubSettings/addLeagueTeamThunk',
  async (payload: addLeagueTeamType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubSettingsPageAPIs.addLeagueTeamAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return { ...results, ...payload };
  },
);

export const updateLeagueTeamThunk = createAsyncThunk(
  'clubSettings/updateLeagueTeamThunk',
  async (payload: updateLeagueTeamType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.updateLeagueTeamAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const deleteLeagueTeamThunk = createAsyncThunk(
  'clubSettings/deleteLeagueTeamThunk',
  async (payload: deleteLeagueTeamType, { dispatch, rejectWithValue }) => {
    try {
      const token = await genericTokenCheck(dispatch);
      await ClubSettingsPageAPIs.deleteLeagueTeamAPI(token, payload);
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return payload;
  },
);

export const getAllMembersInLeagueThunk = createAsyncThunk(
  'clubSettings/getAllMembersInLeagueThunk',
  async (payload: getAllMembersInLeagueType, { dispatch, rejectWithValue }) => {
    let results;
    try {
      const token = await genericTokenCheck(dispatch);
      results = await ClubSettingsPageAPIs.getAllMembersInLeagueAPI(
        token,
        payload,
      );
    } catch (err: any) {
      const errorReject = await processThunkError(
        err,
        dispatch,
        'CLUB_SETTINGS_PAGE',
      );
      return rejectWithValue(errorReject);
    }

    return results;
  },
);
